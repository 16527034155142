import {
  Typography, Checkbox, FormControl, MenuItem,
  InputBase,
  IconButton,
  TextField,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { HelpOutlineRounded } from '@mui/icons-material';
import { CloseIcon } from '../../assets/svgs';

export const CFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    background-color: transparent;
    border: 0.5px solid #d8d8d8;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #606060;
    height: 2.875rem;
    line-height: .5rem;
    padding-left: 5px;
    width: 100%;

    &::before {
      content: " ";
      border-bottom: 0;
    }
  }
`;

export const Label = styled(Typography)`
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: .2rem;
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
  padding: 2px 20px;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;

export const MenuItems = styled(MenuItem)`
  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
  &:hover {
    background-color: #F3F9FF;
  }
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;

export const HelpOutlineStyled = styled(HelpOutlineRounded)`
  width: 15px;
  height: 15px;
  color: #78aada;
`;

export const TooltipTitle = styled(Typography)`
  font-size: .875rem;
  font-weight: 700;
  color: #F5F5F5;
`;

export const ToolTipContent = styled(Typography)`
  font-size: .75rem;
  font-weight: 400;
  color: #ECF6FF;
`;

export const SelectInput = styled('div')`
  border: 1px solid #D0D5DD;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280D;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchInput = styled(InputBase)`

`;

export const SearchContainer = styled(Box)`
  align-items: center;
  width: 100%;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };
`;

export const SearchTextField = styled(TextField)`
  background: #f8f9fb;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #E7E8E9;


  & .MuiOutlinedInput-input {
    font-size: 2.2rem;
    margin-top: 5px;
  };
  & .MuiOutlinedInput-root {
    border-radius: 10px;
    @media (max-width: 991px) {
      padding: 0;
    };
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    height: 2.6rem;
    & .MuiOutlinedInput-input {
      font-size: .9rem;
      font-weight: 500;
      text-align: left;
    };
    & .MuiOutlinedInput-adornedStart {
      padding-left: 30px;
    }
    & .MuiOutlinedInput-root {
      height: 2.2rem;
    };
  }

  @media (max-width: 991px) {
    border: 2px solid #E7E8E9;
    padding: 5px 0;
    border-radius: 20px;

    & .MuiOutlinedInput-root {
      padding: 0;
    };
  }
`;

export const SearchButton = styled(IconButton)`
  padding: 19px 28px;
  color: #235A91;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:disabled {
    color: #235A91;
  }

  @media(min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 35px;

  @media(min-width: 992px) {
    width:  18px;
  }
`;
