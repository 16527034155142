import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  BodyCell, MainTableRow, ColorGrid, Dot
} from './returnRow.styles';

const ReturnRow = ({
  row, index, tab
}) => {
  const {
    id, dateCreated, status, businessName, businessType, orderTotal, order, total, datePlaced: date, sortedValue: sortedVal, businessId,
    categoryMarkupAggregated, deliveryFee
  } = row;

  const navigate = useNavigate();
  const orderDate = tab === 'sellers' ? dateCreated : date;
  const datePlaced = moment(orderDate).format('DD/MM/YY (h:mm a)');
  const totalAmount = tab === 'sellers' ? total : orderTotal;
  const totalOrderValue = currencyFormatter(totalAmount);
  const categoryMarkup = currencyFormatter(categoryMarkupAggregated);

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
      <Dot background={bkg} />
      {name}
    </ColorGrid>
  );

  const getStatus = () => {
    switch (status) {
      case 'ORDER_PLACED':
        return getColorGrid('#07DE10', '#00B588', 'Order placed');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#F29053', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#F29053', 'Ready for ...');
      case 'ORDER_REJECTED':
        return getColorGrid('#606060', '#FF0F0F', 'Rejected');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#F29053', 'Order Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#F29053', 'Order sent');
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#F29053', 'Completed');
      case 'ORDER_AWAITING_APPROVAL':
        return getColorGrid('#606060', '#F29053', 'Awaiting Approval');
      case 'CLOSED':
        return getColorGrid('#07DE10', '#00B588', 'Completed');
      default:
        return getColorGrid('#606060', '#00B588', 'Order Received');
    }
  };
  const tov = `₦ ${totalOrderValue}`;
  const sortedValue = `₦ ${currencyFormatter(sortedVal)}`;
  const fee = `₦ ${currencyFormatter(deliveryFee)}`;
  const orderMarkup = `₦ ${currencyFormatter(categoryMarkup)}`;
  const customersName = order?.business?.name || '';

  const erpCells = () => {
    switch (tab) {
      case 'buyers':
        return [datePlaced, businessName, businessType.toLowerCase(), orderMarkup, fee, tov, sortedValue];
      case 'sellers':
        return [datePlaced, customersName, businessName, businessType.toLowerCase(), fee, tov, sortedValue];
      default:
        return null;
    }
  };

  const handleClick = () => {
    if (tab === 'sellers') {
      navigate(`/order-details/${id}`, { state: { businessId, isSeller: true } });
    } else {
      navigate(`/package-list/${id}`);
    }
  };

  const returnCell = (val) => val;

  const renderBodyCells = () => erpCells().map((cell) => (
    <BodyCell key={cell} onClick={() => handleClick(id)} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
      {renderBodyCells()}
      { tab === 'sellers' ? <BodyCell>{getStatus()}</BodyCell> : '' }
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  tab: PropTypes.string.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
