import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, Menu, MenuItem, Tooltip, tooltipClasses
} from '@mui/material';
import styled from 'styled-components';
import { ArrowDropDown } from '@mui/icons-material';
import {
  CFormControl, Label, SelectNameTypo, FieldCheckbox, CheckboxImage,
  HelperText, TooltipTitle, ToolTipContent, HelpOutlineStyled,
  SelectInput, SearchContainer, SearchTextField, SearchButton,
  SearchImage,
  MenuItems
} from './customSelectWithSearch.styles';
import CheckboxIcon from '../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../assets/images/supplier/Checkbox-resting.png';
import SearchIcon from '../../assets/images/marketOrder/searchIcon.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#235A91',
    color: '#fff',
    fontSize: theme?.typography?.pxToRem(12),
    border: '1px solid #235a91',
    borderRadius: '8px',
    padding: '.5rem 0.5rem .5rem 1rem',
    minWidth: '21rem !important',
    marginLeft: '15rem',
    height: '5rem',
    '&[x-placement*="top"] $arrow': {
      bottom: '-8px',
      left: '50%',
      borderWidth: '8px 8px 0',
      borderTopColor: '#235A91',
    },
  },
  '.MuiTooltip-arrow': {
    color: '#235A91',
    marginLeft: '-7.5rem',
  },
}));

const CustomSelectWithSearch = ({
  field, value, handleChange, showCheckBox, placeholder, helperText, error, required,
  isTooltip, tooltipDesc, ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(field?.options || []);
  const [selected, setSelected] = useState([value]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const updatedOptions = field?.options?.filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredOptions(updatedOptions);
  };

  const handleOptionSelect = (option) => {
    setSelected([option]);
    handleChange({ target: { name: field.name, value: option } });
    handleCloseMenu();
    setSearch('');
    setFilteredOptions(field?.options || []);
  };

  return (
    <Grid item container direction="column">
      {field.label && (
        <Label error={error}>
          {field.label}
          {required && <span style={{ color: 'red' }}>*</span>}
          {isTooltip && (
            <HtmlTooltip
              title={(
                <>
                  <TooltipTitle>Quick Guide</TooltipTitle>
                  <ToolTipContent>{tooltipDesc}</ToolTipContent>
                </>
              )}
              arrow
            >
              <HelpOutlineStyled />
            </HtmlTooltip>
          )}
        </Label>
      )}

      <CFormControl fullWidth variant="filled" size="small" {...props}>
        <SelectInput
          onClick={handleOpenMenu}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleOpenMenu();
            }
          }}
          role="button"
          tabIndex={0}
        >
          {selected[0] || placeholder}
          <ArrowDropDown />
        </SelectInput>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          PaperProps={{
            style: { maxHeight: 300, width: '29%' },
          }}
        >
          <MenuItem disableRipple>
            <SearchContainer>
              <SearchTextField
                variant="outlined"
                label=""
                value={search}
                onChange={(e) => handleSearchChange(e)}
                placeholder="Search..."
                fullWidth
                onKeyDown={(e) => e.stopPropagation()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: '20px' }}>
                      <SearchButton
                        aria-label="Search..."
                        edge="start"
                        disabled={!search}
                        onClick={handleSearchChange}
                      >
                        <SearchImage alt="search icon" src={SearchIcon} />
                      </SearchButton>
                    </InputAdornment>
                  ),
                }}
              />
            </SearchContainer>
          </MenuItem>

          {filteredOptions.map((option) => (
            <MenuItems key={option} onClick={() => handleOptionSelect(option)}>
              <Grid container justifyContent="space-between" alignItems="center">
                <SelectNameTypo>{option}</SelectNameTypo>
                {showCheckBox && (
                  <FieldCheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected.includes(option)}
                  />
                )}
              </Grid>
            </MenuItems>
          ))}
        </Menu>
      </CFormControl>

      {helperText && error && <HelperText>{helperText}</HelperText>}
    </Grid>
  );
};

CustomSelectWithSearch.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  showCheckBox: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  isTooltip: PropTypes.bool,
  tooltipDesc: PropTypes.string,
};

CustomSelectWithSearch.defaultProps = {
  field: {},
  value: '',
  showCheckBox: true,
  placeholder: '',
  error: false,
  required: false,
  helperText: '',
  isTooltip: false,
  tooltipDesc: '',
};

export default CustomSelectWithSearch;
