import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Box, Hidden, useMediaQuery } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  FooterGrid,
  NoResultsContainer,
  NoResultsHeader,
} from './newOrder.styles';
import ProductCard from './productCard';
import CatalogueActionTypes from '../../../providers/reducers/catalogue/catalogueTypes';
import { NEW_ORDER_PRODUCTS_PAGE_QUERY } from '../../../queries/products';
import { useStateValue } from '../../../providers/stateProvider';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import TablePagination from '../../shared/tablePagination';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import UnverifiedComponent from './unverifiedComponent';
import ProductStaticFooter from './productStaticFooter';

const NewOrder = ({
  state,
  view,
}) => {
  const {
    searchValue, priceRange, productVariant, productDeliveryVariant
  } = state;

  const handleAlertOpen = (msg) => toast(msg);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const [pageCount, setPageCount] = useSearchParamsState('pc', '20');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const [{
    user: { session: { isBuyerVerified } }
  }, dispatch] = Object.values(useStateValue());

  const updateCatalogueState = (products, newOrderPageProductsTotalNumber) => {
    dispatch({
      type: CatalogueActionTypes.UPDATE_CATALOGUE,
      payload: {
        products,
        totalCount: newOrderPageProductsTotalNumber
      }
    });
  };

  const variables = {
    search: '',
    ...(priceRange && { priceRange }),
    ...(productVariant && { productVariant }),
    ...(productDeliveryVariant && { productDeliveryVariant }),
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    marketProducts: true,
    productName: searchValue,
  };

  const { loading, data, refetch } = useQuery(NEW_ORDER_PRODUCTS_PAGE_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  useEffect(() => {
    if (data && data.newOrderPageProducts) {
      const { products, productsTotalNumber } = data;
      updateCatalogueState(products, productsTotalNumber);
    }
  }, [data]);

  const { products = [], productsTotalNumber = 0 } = data || {};

  return (
    <>
      { loading ? <NewOrdersLoader column={4} />
        : !isBuyerVerified && !loading ? (
          <UnverifiedComponent />
        ) : (
          <Box style={{ marginTop: '1rem', position: '' }}>
            <ProductCardsContainer container spacing={3}>
              {products?.length
                ? products?.map((product, index) => (
                  <ProductCardGrid key={product.id} item view={view}>
                    <ProductCard
                      product={product}
                      openAlert={handleAlertOpen}
                      index={index}
                      refetch={refetch}
                      view={view}
                    />
                  </ProductCardGrid>
                )) : (
                  <NoResultsContainer>
                    <NoResultsHeader>
                      Products Not Found!
                    </NoResultsHeader>
                    <NoResultsText>
                      Please we currently don’t have the product you searched for,
                      <br />
                      check out our recommended products below
                    </NoResultsText>
                  </NoResultsContainer>
                )}
            </ProductCardsContainer>
            <FooterGrid item container className="joy-new-order-pagination">
              <TablePagination
                total={productsTotalNumber}
                pageCount={+pageCount}
                setPageCount={setPageCount}
                pageNumber={+pageNumber}
                setPageNumber={setPageNumber}
                hideRowsPerPage
                displayShowResult
              />
            </FooterGrid>
          </Box>
        )}

      {/* Mobile menu  */}
      <Hidden mdUp>
        <ProductStaticFooter />
      </Hidden>
    </>
  );
};

NewOrder.propTypes = {
  state: PropTypes.instanceOf(Object),
  view: PropTypes.string
};

NewOrder.defaultProps = {
  state: {},
  view: 'grid',
};

export default NewOrder;
