import {
  Grid, Typography, Button, CircularProgress,
  TextField, ButtonGroup, Box, Card, CardMedia
} from '@mui/material';
import styled, { css } from 'styled-components';
import {
  CartBag, MinusFrame, AddFrame, MarkIcon,
  CartItem
} from '../../../assets/svgs';
import { PopperContainer as PromoDetailsPopper } from './promoDetailsPopper.styles';

export const CardPaper = styled(Card)`
  border-radius: 1rem;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  background: #FFFFFF;
  border: 0.5px solid #E7E8E9;
  box-shadow: 0px 5.98px 23.91px 0px #94949414;
  padding: .5rem .5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 991px) {
    border-radius: 3rem;
    // padding: 1rem 1.5rem 1rem 3rem;

    ${PromoDetailsPopper} {
      display: ${({ openDetails }) => (openDetails ? 'block' : 'none')};
      z-index: 1;
    }
  }
`;

export const CardWrapper = styled(Grid)`
  width: 100%;
`;

export const ImgBox = styled('div')`
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 186px;
  position: relative;

  @media (max-width: 991px) {
    height: 50%;
    width: 100%;
    margin: auto;
    padding: 0;
  }
`;

export const ProductImg = styled(CardMedia)`
  width: ${(props) => (props.cart ? '90%' : '100%')};
  height: 90%;
  object-fit: contain;

  @media (max-width: 991px) {
    width: 90%;
    min-height: 340px;
    max-height: ${(props) => (props.cart ? '' : '186px')};
  }
`;

export const DetailsBox = styled('div')`
  padding: 5px 15px;

  @media (min-width: 992px) {
    position: relative;

    ${PromoDetailsPopper} {
      display: none;
    }

    &:hover ${PromoDetailsPopper} {
      display: block;
      z-index: 1;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
`;

export const DetailName = styled(Typography)`
  font-size: 1em;
  font-weight: 550;
`;

export const DetailPack = styled(Typography)`
  color: #F2EFEE;
`;

export const PriceTypo = styled(Typography)`
  font-size: 1.5em;
  font-weight: 600;
`;

export const SavingsTypo = styled(Typography)`
`;

export const PromoGrid = styled(Grid)`
  margin: 10px 0;

  @media(min-width: 992px) {
    margin: 15px;
    margin-left: 0px;
  }
`;

export const PromoImg = styled('img')`
  width: 25px;
  margin-right: 10px;
  margin-bottom: -7px;

  @media(min-width: 992px) {
    width: 18px;
  }
`;

export const StatusContainer = styled(Grid)`
justify-content: space-between;
align-items: center;


`;

export const PromoOffer = styled(Typography)`
  font-size: 1.563rem;
  font-weight: 500;
  color: #AF1839;

  @media(min-width: 992px) {
    font-size: .938rem;
  }
`;

export const HeaderContainer = styled(Grid)`
  width: 100%;
  padding: 3px 0px;
  flexGrow: 1;

  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const DetailsContainer = styled('div')`
  width: 100%;
`;
export const ActionsContainer = styled(Grid)`
  padding: 0px 1rem;
  @media(max-width: 991px) {
  }
`;

export const AddCartGrid = styled(Grid)`
`;

export const AddCartListGrid = styled(Grid)`
  margin-top: 4rem;
  padding-left: 2rem;
`;

export const ActionBox = styled(Box)`
  diplay: flex;
  flex-direction: row;
`;

export const HeaderText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #212121;

  @media(min-width: 992px) {
    line-height: 20px;
    font-weight: 700;
  }
  @media(max-width: 991px) {
    font-weight: 700;
    margin-bottom: 10px;
    max-width: 35rem;
    font-size: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const TypoContainer = styled(Grid)`
  margin: 5px 0px;
`;

export const ImageContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const PackSizeTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  color: #616161;
  line-height: 45px;

  @media(min-width: 992px) {
    font-size: .875rem;
    line-height: 25px;
  }
`;

export const PackSizeTypoText = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  color: #616161;
  line-height: 45px;
  display: flex;
  align-items: center;
  gap: 15px;

  @media(min-width: 992px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const PricingAndSaving = styled(Grid)`
  align-items: center;
`;

export const PromoPriceText = styled(Typography)`
  font-size: .8rem;
  font-weight: 500;
  text-decoration-line: line-through;
  margin-left: auto;
  color: #616161;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;

export const PricingWrapper = styled(Grid)`
`;

export const SavingsContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const SavingsGrid = styled(Grid)`
`;

export const Pricing = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  color: #212121;
  line-height: 24px;

  @media(min-width: 992px) {
    font-size: 1.25rem;
  }

  @media(max-width: 991px) {
    font-size: 2.2rem;
    margin: 0.6rem 0;
  }
`;

export const ProductImage = styled('div')`
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const PromoImage = styled('div')`
  width: 25px;
`;

export const ActionButton = styled(Button)`
  border: none !important;
  background: none !important;
  box-shadow: none !important;

  &:disabled {
    border: none !important;
    background: none !important;
  }

  &:hover {
    border: none;
    background: none !important;
  }

  @media (min-width: 992px) {
    &.MuiButtonGroup-grouped {
      min-width: 0;
    }

    &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
    }
  }
`;

export const InputButton = styled(Button)`
  font-size: 1rem;
  padding: 5px 24px;
  font-weight: 400;
  background-color: #F0F0F0;
  width: 3.7rem;

  &:disabled {
    color: #424242;
    border: 1px solid #F0F0F0;
    border-right: #F0F0F0;
  }
`;

export const InputButtonGroup = styled(ButtonGroup)`
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.MuiButtonGroup-root {
    width: 100%;
    padding: 3px 0px;
  }

  @media (max-width: 991px) {
    &.MuiButtonGroup-root {
      width: 90%;
      padding: 3px 0px;
    }
  }
`;

export const ActionTextField = styled(TextField)`
& .MuiInputBase-input {
  text-align: center;
  font-weight: 600;
  font-size: 1.313rem;
  color: #212121;
}
  & ::placeholder {
    text-align: center;
    color: #212121;
    font-weight: 600;
    font-size: 1.313rem;
  }

  & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
    font-size: 1.3em;
    width: 1.2rem;
    text-align: center;
    color: #212121;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 3rem;
      width: 10rem;
    }
    & .MuiInputBase-root {
      font-size: 2.7rem;
      padding: 5px 15px 5px;
    }
    & .MuiInputBase-input {
      text-align: center;
      font-weight: 600;
      font-size: 2.6rem;
      color: #212121;
    }
    & ::placeholder {
      text-align: center;
      color: #212121;
      font-weight: 600;
      font-size: 2.6rem;
    }
  }

  @media (min-width: 992px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 1.313rem;
    }
  }
`;

export const ActionTextBox = styled(Box)`
`;

export const AddButton = styled(Button)`
  border-radius: 8px !important;
  background-color: #235A91;
  color: #fff !important;
  width: 100% !important;
  margin-top: 5px;

  &:hover {
    color: #fff;
    background-color: #235a91;
  }

  &.MuiButton-root.Mui-disabled {
    color: ${(props) => (props.outOfStock ? '#FFF' : ' F1F8FF')};
    background-color: ${(props) => (props.outOfStock ? '#92C5E7' : ' #235A91')};
  }

  &.MuiButton-contained.Mui-disabled {
    color: #F1F8FF;
    background-color: #92C5E7;
  }

  @media(min-width: 992px) {
    border-radius: 8px !important;
    padding: 10px 0px;
    height: 40px;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 15px;
    height: 5rem !important;
    border-radius: 16px !important;
  }
`;

export const SubscribeButton = styled(Button)`
  border-radius: 8px;
  color:  #235A91;
  width: 100% !important;
  margin-top: 5px;

  &:hover {
    background-color: #fff;
    color: #235a91;
  }

  &.MuiButton-outlined.Mui-disabled {
    border: .8px solid #235A91;
  }

  &.MuiButton-root.Mui-disabled {
    color: #235A91;
  }

  &.MuiButton-contained.Mui-disabled {
    color: #235A91;
    background-color: #FFF;
  }

  @media(min-width: 992px) {
    border-radius: 8px;
    padding: 10px 0px;
    height: 45px;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 15px;
    height: 5rem !important;
    border-radius: 16px !important;
  }
`;

export const ConfirmationButton = styled(Button)`
  border-radius: 8px;
  color:  #235A91;
  width: 100%;
  margin-top: 5px;
  background-color: #ECF6FF;
  border: none;

  &:hover {
    background-color: #ECF6FF;
    border: none;
  }

  @media(min-width: 992px) {
    border-radius: 4px;
    padding: 7px 5px 7px 10px;
    height: 45px;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 15px;
    height: 5rem;
    margin-top: 1rem;
  }
`;

const returnFontSize = (value) => {
  switch (value.type) {
    case 'similarAction':
      return '1.3rem';
    case 'notify':
      return '1.8rem';
    default:
      return '1.6rem';
  }
};

export const AddCartTypo = styled(Typography)`
  font-size: ${({ type }) => (type === 'notify' ? '.8rem' : '.9rem')};
  font-weight: 600;
  text-transform: none;

  @media (max-width: 991px) {
    margin-left: 3px;
    font-size: ${returnFontSize}
  }
`;

export const NotificationTypo = styled(Typography)`
  font-size: .6rem;
  font-weight: 500;
  text-align: left;
  padding-left: 7px;

  @media (max-width: 991px) {
    margin-left: 3px;
    font-size: ${(props) => (props.similarAction ? '1.3rem' : '1.4rem')}
  }
`;

export const ShoppingCartImg = styled(CartBag)`
color: ${(props) => (props.outOfStock ? '#92C5E7' : ' #235A91')};
&.MuiSvgIcon-root {
  width: 17px;
  height: 15px;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    width: 35px;
    height: 35px;
  }
}
`;

export const MarkIconImg = styled(MarkIcon)`
&.MuiSvgIcon-root {
  width: 17px;
  height: 15px;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
}
`;

export const CartItemIcon = styled(CartItem)`
&.MuiSvgIcon-root {
  width: 20px;
  height: 19px;
  color: #fff;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
}
`;

export const PlusIcon = styled(AddFrame)`
&.MuiSvgIcon-root {
  font-size: 3rem;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    font-size: 6.5rem;
  }
}
`;

export const MinusIcon = styled(MinusFrame)`
  &.MuiSvgIcon-root {
    font-size: 3rem;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      font-size: 6.5rem;
    }
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
`;

const isAvailableContainer = css`
  background-color: #f5ecff;
`;

const isStockOutContainer = css`
  background-color: #fff4f3;
`;

const isMutableContainer = css`
  background-color: #fff4de;
`;

const isClearanceContainer = css`
background-color: #F3F9FF;
`;

const getContainerStyles = ({ status }) => {
  switch (status) {
    case 'available':
      return isAvailableContainer;
    case 'stockOut':
      return isStockOutContainer;
    case 'mutable':
      return isMutableContainer;
    case 'clearanceSale':
      return isClearanceContainer;
    default:
      return isAvailableContainer;
  }
};

export const ProductVContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  justify-content: center;
  margin: .5rem;
  border-radius: 2.117rem;
  width: fit-content;

  @media(max-width: 991px) {
    padding: 12px 30px;
    position: relative;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  ${getContainerStyles}
`;

const isAvailable = css`
  background-color: #7a2ecc;
`;

const isStockOut = css`
  background-color: #e74c3c;
`;

const isMutable = css`
  background-color: #e79800;
`;
const isClearance = css`
  background-color: #235A91;
`;

const getStyles = ({ type }) => {
  switch (type) {
    case 'available':
      return isAvailable;
    case 'stockOut':
      return isStockOut;
    case 'mutable':
      return isMutable;
    case 'clearanceSale':
      return isClearance;
    default:
      return isAvailable;
  }
};

export const DivBullet = styled('span')`
  height: 8px;
  width: 8px;
  border-radius: 50%;

  @media (max-width: 991px) {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  ${getStyles}
`;

const isAvailableText = css`
  color: #7a2ecc;
`;

const isStockOutText = css`
  color: #E42900;
`;

const isMutableText = css`
  color: #e79800;
`;
const isClearanceTest = css`
  color: #235A91;
`;

const getTextStyles = ({ status }) => {
  switch (status) {
    case 'available':
      return isAvailableText;
    case 'stockOut':
      return isStockOutText;
    case 'mutable':
      return isMutableText;
    case 'clearanceSale':
      return isClearanceTest;
    default:
      return isAvailableText;
  }
};

export const ProductVariantText = styled('div')`
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  font-size: .688rem;

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }

  ${getTextStyles}
`;

export const Divider = styled('span')`
  font-weight: 700;
  font-size: 1.063rem;

  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const ChangeBox = styled(Typography)`
  padding: 5px 3px;
  background-color: #EDF6FF;
  color: #235A91;
  font-size: .563rem;
  border-radius: 5px;
  font-weight: 600;
  margin-left: 7px;
  text-align: center;

  @media(max-width: 991px) {
    font-size: 1.3rem;
    font-weight: 450;
    padding: 12px 4px;
    margin-left: 11px;
    white-space: nowrap;
  }
`;

export const ChangeWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media(min-width: 992px) {
    width: 100%;
    // margin-bottom: .5rem;
  }
`;

export const PromoContainer = styled(Box)`
  background: #ffdad6;
  border-radius: 7px;
  width: 3rem;
  height: 2.313rem;

  @media (max-width: 991px) {
    height: 4.626rem;
    width: 6rem;
    border-radius: 10px;
  }
`;

export const PromoText = styled(Typography)`
  font-size: .75rem;
  color: #E83A25;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;

  @media (max-width: 991px) {
    font-size: 1.6rem;
    padding: 20px 10px;
  }
`;

export const DividerLine = styled('hr')`
  color: #616161;
  font-size: 14px;
  font-weight: 500;
  height: 15px;
    margin: 0;
    padding: 0;

`;

export const ManufacTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  color: #616161;
  line-height: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;

  @media(min-width: 992px) {
    font-size: .875rem;
    line-height: 20px;
    width: 100%;
  }
`;

const isFree = css`
  background: #E0FCEE;
  color: #008F3D;
`;

const isCharged = css`
  background: #FFF6E8;
  color: #E27500;
`;

const getDeliveryStatus = ({ status }) => {
  switch (status) {
    case 'free':
      return isFree;
    case 'charge':
      return isCharged;
    default:
      return isFree;
  }
};

export const DeliveryContainer = styled('div')`
  font-size: .75rem;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  border-radius: 6px;
  padding: 0 10px;
  margin-bottom: 5px;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 1.5rem;
    padding: 5px 10px;
    margin-bottom: 10px;
    gap: 0;
  }

  ${getDeliveryStatus}
`;
