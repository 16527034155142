import { styled } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

export const PaymentSummaryCardHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ECECEC;

  @media (min-width: 992px) {
    padding-top: 3.5rem;
    padding-bottom: 17px;
    border-bottom: 1px solid #E8E8E8;
  }
  @media (max-width: 991px) {
    margin-bottom: 5rem;
  }
`;

export const PaymentSummaryCardHeaderTitle = styled('p')`
  margin: 0;
  color: #424242;
  font-weight: 700;
  font-size: 2.5rem;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const PaymentSummaryRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  @media (max-width: 991px) {
    margin-bottom: 4rem;
  }
`;

export const PaymentSummaryRowTitle = styled('p')`
  color: #424242;
  font-size: 2.2rem;
  line-height: 32px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:nth-child(2n) {
    margin-left: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const PaymentSummaryRowBoldTitle = styled('p')`
color: #424242;
font-size: 2.2rem;
line-height: 32px;
font-weight: 700;
margin: 0;

&:nth-child(2n) {
  margin-left: 4rem;
}

@media (min-width: 992px) {
  font-size: 15px;
}
`;

export const PaymentSummaryGrandTotalRow = styled('div')`
display: flex;
align-items: center;
border-top: 1px solid #E8E8E8;
padding-top: 20px;
margin-top: 20px;
justify-content: space-between;

@media (max-width: 991px) {
  padding-top: 35px;
  border-top: 2px solid #ECECEC;
}
`;

export const PayNowButton = styled(Button)`
  background-color: #235A91;
  border-radius: 5px;
  color: #fff;
  padding: 15px 10px;
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 991px) {
    font-size: 2rem;
    margin-top: 5rem;
    padding: 20px 10px;
  }
  &:hover {
    background-color: #235A91;
  }
  &:disabled {
    background-color: #235A91;
    color: #FFFFFF;
  }
`;

export const ContinueButton = styled(Button)`
  border: 1px solid #235A91;
  font-size: .8rem;
  border-radius: 5px;
  color: #235A91;
  padding: 15px 10px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  @media (max-width: 991px) {
    font-size: 2rem;
    padding: 20px 10px;
  }
`;

export const TipTool = styled(ErrorOutline)`
  color: #3498DB;
  cursor: pointer;
  transform: rotate(180deg);
  width: 15px;
  @media (max-width: 991px) {
    width: 40px;
  }
`;

export const TooltipWrapper = styled('div')`
  background: #D1E9FF;
  border: 1px solid #EFF8FF;
  border-radius: 50%;
  padding: 0 5px;
  display: flex;
  @media (max-width: 991px) {
    padding: 7px 1px;
  }
`;

export const NoteWrapper = styled(Grid)`
  background: #F3F9FF;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  @media (max-width: 991px) {
    padding: 30px;
    margin-top: 35px;
  }
`;

export const Note = styled(Typography)`
  color: #606060;
  font-size: .75rem;
  font-weight: 700;
  line-height: 19.56px;
  display: flex;
  border-bottom: 1px solid #E5EBF0;
  padding: 0px 0px 13px;
  gap: 5px;
  @media (max-width: 991px) {
    font-size: 2rem;
    line-height: 30px;
    padding: 0px 0px 20px;
    border-bottom: 1.5px solid #E5EBF0;
    gap: 7px;

  }
`;

export const NoteTitle = styled(Typography)`
  color: #235A91;
  font-size: .75rem;
  font-weight: 700;
  line-height: 19.56px;
  text-align: left;
  padding-top: 13px;
  @media (max-width: 991px) {
    font-size: 2rem;
     line-height: 30px;
      padding: 0px 0px 20px;
       margin-top: 25px;
  }
`;

export const NoteDesc = styled(Typography)`
  color: #606060;
  font-size: .75rem;
  font-weight: 400;
  line-height: 19.56px;
  text-align: left;
  @media (max-width: 991px) {
    font-size: 2rem;
     line-height: 40px;
      padding: 0px 0px 20px;
  }
`;
