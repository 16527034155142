import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Hidden from '@mui/material/Hidden';
import {
  Grid, Dialog, DialogActions, DialogContent, DialogTitle,
  Slide, Checkbox, TableRow, TableBody, TableCell,
  useMediaQuery
} from '@mui/material';
import { toast } from 'react-hot-toast';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import MainContent from '../customComponents/mainContent';
import {
  CartGridContainer, DialogActionButtonText, BackArrowIconContainer,
  PageTitleText, CartItemCountContainer, CartItemCountText, DeliveryAddressCard, DeliveryAddressCard2,
  DeliveryAddressCardSection, DeliveryAddressCardHeader, DeliveryAddressCardHeaderTitle, DeliveryAddressCardHeaderSubTitle,
  SupplierCardPaper, CartGridContainerHeader, DialogCancelButton, DialogOkButton, KeyboardArrowLeft, ChangeOutletRow,
  SelectOutletText, OutletInfoContainer, OutletContactLine, ManualMethodImage, ManualDialogDoneButton, EmptyCartImg,
  ManualDialogCancelButton, ManualDialogPadder, ManualPlaceOrder, OptionText, ManualSupplierItem, Span,
  CircularProgressLoader, LoaderGrid, ArrowBackText, ClearBox, ClearAllCart, TitleText, ContentText,
  CartItemWrapper, CartContainer, MainTableRow, Header, TableHeader, OutletContactHeader, ViewMore, GridContainer,
  GridWrapper, ItemWrapper, ItemTypo, EditContainer, TableWrapper, EmptyCardPaper, TextWrapper,
  TextHeader, CartItemContainer, PaymentCardSection, TextBody, IconWrapper, DeliveryIcon, Default, SearchGrid, SupplierCard, FooterGrid,
  CartItemCountFlex,
  NoOutletInfoContainer,
  NoOutletInfoTitle,
  NoOutletInfoText,
  ClearAllCartMobile
} from './cart.styles';
import ProductItem from './product-item/index';
import CreateOutletModal from '../shared/addSingleOutlet/individualOutletDialog';
import {
  UPDATE_ORDER_PRODUCTS_MUTATION, PLACE_ORDER_PRODUCTS_MUTATION, UPDATE_CART_PRODUCT,
  PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION, SWITCH_ORDER_PRODUCT_SUPPLIER, PLACE_ORDER,
  CLEAR_CART
} from '../../mutations/cart';
import { SAVED_CART, GET_CART_ITEMS, CART_PAYMENT_SUMMARY } from '../../queries/cart';
import PaymentSummary from './paymentSummaryCard/PaymentSummary';
import ReturnSelectField from '../suppliers/individual/returnSelectField';
import Print from '../../assets/images/Print.png';
import Pdf from '../../assets/images/select.png';
import Email from '../../assets/images/Email.png';
import EmptyCart from '../../assets/images/empty_cart.gif';
import SupplierOrderForm from '../../assets/images/manual_supplier_order.png';
import { SupplierOrderPrint } from './supplierOrderPrint';
import {
  TableContainerWrapper, TableComponent, HeaderCell,
  MainTableHead, AddProductCardSection, AddProductCard, CartItemButton, CartItemButtonContainer
} from './newProduct.styles';
import { BulkUploadButton, Delete, ShopNowButton } from './product-item/styles';
import Floater from './floater';
import { Product } from '../../providers/reducers/product/classes/Product';
import { DELETE_DELIVERY_LOCATION } from '../../mutations/outlets';
import { OgaToken } from '../../graphql/token';
import { RenderPopper } from '../../utils/utils';
import ConfirmDialog from './confirmDialog';
import SimilarProducts from './similarProducts/similarProduct';
import ClearCartDialog from './clearCartDialog';
import {
  Dots, CartDot, CartIco, NoAddressIcon
} from '../../assets/svgs';
import ActionPopper from './actionPopper';
import SaveDialog from './saveCart';
import CartSaved from './CartSaved';
import SavedCartDialog from './SavedCart';
import NoSavedCart from './NoSavedCart';
import { useStateValue } from '../../providers/stateProvider';
import { parseJson } from '../affiliates/utils';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import CartProcessingModal from './cartProcessingModal';
import { convertToCapitalization } from '../shared/methods';
import SuccessDialog from '../shared/successDialog';
import UploadFlow from '../orders/shared/uploadFlow';
import MapOrderCsvDialog from '../orders/shared/mapOrderCsvDialog';
import SimpleBadge from '../shared/simpleBadge';
import PriceDisclaimer from '../shared/priceDisclaimer';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const dosages = [
  { name: 'Tablet' }, { name: 'Capsule' }, { name: 'Caplet' }, { name: 'Syrup' }, { name: 'Suspension' }, { name: 'Solution' },
  { name: 'Gel' }, { name: 'Injection' }, { name: 'Infusion' }, { name: 'Suppository' }, { name: 'Pessary' }, { name: 'Cream' },
  { name: 'Ointment' }, { name: 'Lotion' }, { name: 'Paste' }, { name: 'Powder' }, { name: 'Other' }
];

const generateExtraProduct = () => ({
  brandName: '', dosageForm: 'Select', strength: '', packSize: '', quantity: 0
});
const initialState = {
  orderId: null,
  cart: [],
  dataCartLoading: true,
  existingOutlet: null,
  wantExtraProducts: false,
  extraProducts: [generateExtraProduct(), generateExtraProduct(), generateExtraProduct(), generateExtraProduct()],
  acceptedExtraProducts: [],
  toBePrintedManualSupplierOrderForms: new Map(),
  extraPlaceOrderActions: new Map(),
  printSupplierOrder: false,
  serviceFee: 200,
  totalPromo: 0,
  pricePolicyFactor: 1,
  deliveryFee: 0,
  updateLoading: false,
  placeLoading: false,
  showDeleteDialog: false,
  showPlaceOrderDialog: false,
  toBeDeletedItem: null,
  deliveryAddress: '',
  changeLocation: false,
  deliveryLocations: [],
  selectedDeliveryLocation: '',
  addNewOutlet: false,
  deliveryState: '',
  landmark: '',
  city: '',
  country: 'Nigeria',
  placeManualOrder: false,
  openMCF: false,
  openPrintDialog: false,
  openExportModel: false,
  businessUserId: 0,
  viewMoreButton: null,
  deleteAddressDialog: false,
  clearCartDialog: false,
  actionPopper: null,
  saveDialog: false,
  cartSaved: false,
  savedCartDialog: false,
  cartOrder: [],
  noSavedCart: false,
  searchText: '',
  cartOrderProductsCount: 0,
  totalPaymentSummary: 0,
  initialCartItem: [],
  editOutlet: false,
  orderProcessingDialog: false,
};

const CartIndex = ({ manualCart }) => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [openOutletModal, setOpenOutletModal] = useState(false);
  const [existingOutlet, setExistingOutlet] = useState(null);
  const [modalMessage, setModalMessage] = useState({});
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [openPriceDisclaimer, setOpenPriceDisclaimer] = useState(false);
  const [placedOrderNo, setPlacedOrderNo] = useState('');
  const [uploadDescDialog, setUploadDescDialog] = useState(false);
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);

  const handleUploadDescDialog = () => {
    setUploadDescDialog(!uploadDescDialog);
  };

  const handleCsvDialog = () => {
    setUploadCsvDialog(!uploadCsvDialog);
  };

  const [{
    business: { subBusinessesIds },
    user: {
      session,
      businessCanUseMastersList, isBusinessVirtualStore, priceFactor, platform
    },
    cart: { cartStatus }
  }, dispatch] = Object.values(useStateValue());

  const canUseMastersList = businessCanUseMastersList || isBusinessVirtualStore;

  const { alertMessage, alertSeverity } = useLocation();
  const navigate = useNavigate();
  const backRoute = -1;

  if (alertMessage && alertSeverity === 'success') {
    toast.success(alertMessage);
  } else if (alertMessage && alertSeverity === 'error') {
    toast.error(alertMessage);
  }

  const {
    cart, serviceFee, deliveryFee, placeLoading, updateLoading, showDeleteDialog, showPlaceOrderDialog,
    selectedDeliveryLocation, placeManualOrder, toBePrintedManualSupplierOrderForms, orderId,
    printSupplierOrder, extraPlaceOrderActions, extraProducts, wantExtraProducts, showLoan, business, viewMoreButton, deleteAddressDialog, clearCartDialog, actionPopper,
    saveDialog, cartSaved, savedCartDialog, cartOrder, noSavedCart, cartProductsCount, initialCartItem, totalPaymentSummary, orderProcessingDialog, deliveryAddress, deliveryState,
    city, country
  } = state;

  const [switchOrderProductSupplier] = useMutation(SWITCH_ORDER_PRODUCT_SUPPLIER);
  const [clearCart] = useMutation(CLEAR_CART);
  const [deliveryLocation] = useMutation(DELETE_DELIVERY_LOCATION);
  const [updateOrderProducts] = useMutation(UPDATE_ORDER_PRODUCTS_MUTATION);
  const [placeAffiliateOrderProducts] = useMutation(PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION);
  const [placeOrderProducts] = useMutation(PLACE_ORDER_PRODUCTS_MUTATION);
  const [deleteCartProduct] = useMutation(UPDATE_CART_PRODUCT);
  const [updateCartProduct] = useMutation(UPDATE_CART_PRODUCT);
  const [placeOrder, { loading: placeOrderLoading }] = useMutation(PLACE_ORDER);

  const { data: paymentSummary, refetch: refetchPaymentSummary } = useQuery(CART_PAYMENT_SUMMARY);

  const retrieveProducts = (orderSet) => {
    let products = orderSet;

    products = products.map((p) => {
      const {
        name,
        quantity,
        id: orderProductId,
        hybrid,
        alternativeSupplier: { id: alternativeSupplierId, name: alternativeSupplierName },
        supplierOrder: { supplier: { ame: supplierName, settings } },
        product: {
          id, meta, business: { id: productBusinessId }, quantityInStock
        },
        unitCost,
        meta: orderMeta,
        availableQty,
        orderProductStatus
      } = p;

      const {
        clearance_sale: clearanceSale, unit_cost_paid: untiCostPaid, batch_id: batchId
      } = parseJson(orderMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"));

      const supplierSettings = JSON.parse(settings.replace(/'/g, '"').replace('None', '"N/A"'));
      return {
        id,
        orderProductId,
        name,
        quantity,
        hybrid,
        availableQty,
        orderProductStatus,
        alternativeSupplierId,
        alternativeSupplierName,
        manualCart,
        ...new Product(p.product, manualCart && supplierName !== 'OGApharmacy' ? 'erp' : 'marketplace'),
        supplier: supplierName,
        supplierPaymentTerm: supplierSettings.payment_term,
        meta: JSON.parse(meta),
        productBusinessId,
        unitCost: clearanceSale ? untiCostPaid : unitCost,
        batchId,
        quantityInStock
      };
    });

    setState((s) => ({ ...s, cart: products, initialCartItem: products }));
  };

  const adminOrderingForBusiness = localStorage.getItem('oga_ordering_for_affiliate');
  const bizId = localStorage.getItem('userId');
  let businessId = 0;
  if (adminOrderingForBusiness) {
    businessId = JSON.parse(adminOrderingForBusiness).id;
  } else {
    businessId = bizId;
  }
  const variables = {
    bizId: businessId,
    search: searchValue,
    pageCount,
    pageNumber
  };
  if (manualCart) {
    variables.isManualOrder = true;
  }

  const { data: orderData, loading, refetch } = useQuery(GET_CART_ITEMS, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const { data: savedCartData, loading: savedCartLoading, refetch: refetchSaveCart } = useQuery(SAVED_CART, {
    fetchPolicy: 'no-cache',
  });

  const handleOrderProcessingDialog = () => {
    setState((s) => ({
      ...s, orderProcessingDialog: !orderProcessingDialog
    }));
  };

  const handlePlacingOrderProcess = () => {
    if (orderProcessingDialog && cartStatus === 'place_order_processing_completed') {
      const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
      const businessRaw = localStorage.getItem(ogaOrderingForAffiliateKey);
      const _business = businessRaw ? JSON.parse(businessRaw) : null;
      const redirect = _business ? '/orders-admin' : '/orders';
      const url = platform === 'erp' ? '/inventory/orders' : redirect;
      handleOrderProcessingDialog();
      setTimeout(() => {
        navigate(`${url}`);
      }, 1500);
    } else if (orderProcessingDialog && cartStatus === 'place_order_processing_failed') {
      handleOrderProcessingDialog();
    }
  };

  useEffect(() => {
    if (cartStatus) {
      dispatch({ type: CartActionTypes.CART_STATUS, payload: '' });
      refetch();
      if (orderProcessingDialog && cartStatus === 'order_processing_completed') {
        handleOrderProcessingDialog();
      } else {
        handlePlacingOrderProcess();
      }
    }
  }, [cartStatus]);

  useEffect(() => {
    if (orderData && orderData.cartItems) {
      setState((s) => ({
        ...s, cartProductsCount: orderData.cartItemsTotalNumber, cart: orderData.cartItems
      }));
      dispatch({
        type: CartActionTypes.CART_COUNT,
        payload: orderData?.cartItemsTotalNumber
      });
    }

    if (savedCartData && savedCartData.savedCarts) {
      const { savedCarts } = savedCartData;
      setState((s) => ({ ...s, cartOrder: savedCarts, dataCartLoading: false }));
    }

    refetchPaymentSummary();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, savedCartData]);

  const getOutletOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Select Pharmacy', options: [] };
    const { deliveryLocations } = state;
    deliveryLocations.forEach((_deliveryLocation) => {
      optionsBox.options.push(_deliveryLocation.name);
    });
    return optionsBox;
  };

  const selectDosage = () => {
    const optionsBox = { name: 'dosageForm', options: [] };
    dosages.forEach((dosage) => {
      optionsBox.options.push(dosage.name);
    });
    return optionsBox;
  };

  const handleSwitchOrderProductSupplier = (_variables) => {
    switchOrderProductSupplier({
      variables: _variables,
      optimisticResponse: { __typename: 'Mutation' }
    }).then(({ data }) => {
      const {
        switchOrderProductSupplier: {
          order: { id, meta: ordermeta, supplierorderSet }
        }
      } = data;

      const orderMeta = parseJson(ordermeta);

      setState((s) => ({
        ...s,
        updateLoading: false,
        deliveryAddress: orderMeta.delivery_address,
        orderId: id
      }));

      retrieveProducts(supplierorderSet);
      toast.success('Items updated successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, updateLoading: false }));
    });
  };

  const handleChangeDeliveryLocation = (event) => {
    const { value } = event.target;
    setState((s) => ({ ...s, selectedDeliveryLocation: value }));
  };

  const getSelectedDeliveryLocationInfo = () => {
    const { deliveryLocations } = state;
    return deliveryLocations.find((_deliveryLocation) => _deliveryLocation.name === selectedDeliveryLocation);
  };

  const handleOpenViewModal = (event) => {
    setState((s) => ({ ...s, viewMoreButton: viewMoreButton ? null : event?.currentTarget }));
  };

  const handleEditDeliveryLocation = (selectedDeliveryLocationInfo) => {
    handleOpenViewModal();
    setOpenOutletModal(true);
    setExistingOutlet(selectedDeliveryLocationInfo);
  };

  const handleDeliveryAddress = () => {
    setOpenOutletModal(true);
    setExistingOutlet(null);
  };

  const handleCartList = () => {
    window.open(`${OgaToken.SERVER_LINK}download_order_save_cart/${orderId}.pdf`, '_blank');
  };

  const handleClearCart = () => {
    clearCart().then(() => {
      toast.success('Cart items cleared successfully.');
      refetch();
      dispatch({
        type: CartActionTypes.CART_COUNTER,
        payload: 0
      });
      dispatch({
        type: CartActionTypes.UPDATE_CART_ITEMS,
        payload: []
      });
      refetchPaymentSummary();
      setState(initialState);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteDeliveryLocation = (selectedDeliveryLocationInfo) => {
    deliveryLocation({
      variables: {
        deliveryLocationId: +selectedDeliveryLocationInfo.id
      },
      optimisticResponse: {
        __typename: 'Mutation'
      }
    }).then(() => {
      toast.success('Delivery location deleted successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteAddressDialog = (event) => {
    setState((s) => ({
      ...s, deleteAddressDialog: deleteAddressDialog !== false ? false : Boolean(event.currentTarget), viewMoreButton: null
    }));
  };

  const handleClearCartDialog = (event) => {
    setState((s) => ({
      ...s, clearCartDialog: clearCartDialog !== false ? false : Boolean(event.currentTarget)
    }));
  };

  const handleCartDialog = (event) => {
    setState((s) => ({
      ...s, actionPopper: actionPopper ? null : event.currentTarget
    }));
  };

  useEffect(() => {
    if (session) {
      const { businessSet } = session || {};
      const { buyerContact, sellerContact, location } = businessSet?.[0] || {};
      const businessAddress = buyerContact?.landmark ? buyerContact : sellerContact;
      const locationReformat = convertToCapitalization(location?.toLowerCase() || '');
      setState((s) => ({
        ...s, deliveryAddress: businessAddress?.deliveryAddress, city: businessAddress?.city, deliveryState: locationReformat,
        landmark: businessAddress?.landmark
      }));
    }
  }, [session]);

  const renderSelectDeliveryLocationInfo = () => {
    try {
      if (deliveryAddress !== '') {
        return (
          <OutletInfoContainer container>
            <Grid item xs={8}>
              <OutletContactHeader>Delivery Address</OutletContactHeader>
            </Grid>
            <Grid container item xs={4} style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Default>Default</Default>
              <ViewMore onClick={(event) => handleOpenViewModal(event)}>...</ViewMore>
            </Grid>
            <Grid container>
              <OutletContactLine>
                {`${deliveryAddress}, ${city}, ${deliveryState} ${country}`}
              </OutletContactLine>
            </Grid>
          </OutletInfoContainer>
        );
      } return (
        <NoOutletInfoContainer>
          <NoAddressIcon style={{ fontSize: '5rem' }} />
          <NoOutletInfoTitle>No Delivery Location Yet!</NoOutletInfoTitle>
          <NoOutletInfoText>Please add a delivery address for your order delivery</NoOutletInfoText>
        </NoOutletInfoContainer>
      );
    } catch (e) {
      return (
        <NoOutletInfoContainer>
          <NoAddressIcon style={{ fontSize: '5rem' }} />
          <NoOutletInfoTitle>No Delivery Location Yet!</NoOutletInfoTitle>
          <NoOutletInfoText>Please add a delivery address for your order delivery</NoOutletInfoText>
        </NoOutletInfoContainer>
      );
    }
  };

  const updateCartTotal = (supplierorderSet) => {
    dispatch({
      type: CartActionTypes.CART_COUNTER,
      payload: supplierorderSet
    });
  };

  const handleUpdateOrderProducts = () => {
    const { toBeDeletedItem } = state;

    const mutationVariables = [{
      productId: Number(toBeDeletedItem),
      quantity: 0
    }];

    deleteCartProduct({
      variables: { cart: [...mutationVariables] },
    }).then(() => {
      toast.success('Item deleted successfully.');
      refetchPaymentSummary();
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, updateLoading: false }));
    });
  };

  const handlePlaceOrderProducts = () => {
    const role = localStorage.getItem('oga_user_role');
    const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
    const businessRaw = localStorage.getItem(ogaOrderingForAffiliateKey);
    if (businessRaw === null && role === 'oga-pharmacy-admin') {
      setState((s) => ({ ...s, showPlaceOrderDialog: false }));
      return toast.error('Select an affiliate');
    }
    const _business = businessRaw ? JSON.parse(businessRaw) : null;
    const {
      acceptedExtraProducts,
      businessUserId
    } = state;

    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    const extraActions = [];
    const affectedSuppliers = [];
    const extraProductsMeta = acceptedExtraProducts;
    extraPlaceOrderActions.forEach((action) => extraActions.push(action));
    toBePrintedManualSupplierOrderForms.forEach((affectedSupplier) => affectedSuppliers.push(affectedSupplier));
    const mutationVariables = _business ? {
      businessId: _business.id,
      deliveryLocationId: +selectedDeliveryLocationInfo.id,
      extraActions,
      affectedSuppliers,
      extraProductsMeta,
      creditScoreBusiness: _business.id,
      idempotentKey: String(uuid())
    } : {
      cart: [],
      deliveryLocationId: +selectedDeliveryLocationInfo.id,
      extraActions,
      affectedSuppliers,
      extraProductsMeta,
      creditScoreBusiness: businessUserId,
      idempotentKey: String(uuid())
    };
    if (manualCart) {
      mutationVariables.isManualOrder = true;
    }
    setState((s) => ({ ...s, placeLoading: true }));
    setModalMessage({ title: 'Order Processing', desc: 'Please wait while we process your orders.' });
    handleOrderProcessingDialog();

    const mutationFunc = _business ? placeAffiliateOrderProducts : placeOrderProducts;
    mutationFunc({
      variables: mutationVariables,
      optimisticResponse: { __typename: 'Mutation' }
    }).then(({ data }) => {
      let message = '';
      if (_business) {
        message = data.placeAffiliateOrderProducts.message;
        localStorage.removeItem(ogaOrderingForAffiliateKey);
      } else {
        message = data.placeOrderProducts.message;
      }
      toast.success(message);
      setState((s) => ({
        ...s, showPlaceOrderDialog: false, placeLoading: false
      }));
      const redirect = _business ? '/orders-admin' : '/orders';
      const url = platform === 'erp' ? '/inventory/orders' : redirect;
      setTimeout(() => {
        navigate(`${url}`);
      }, 1500);
    }).catch((err) => {
      refetch();
      handleOrderProcessingDialog();
      toast.error(err?.message);
      setState((s) => ({
        ...s, showPlaceOrderDialog: false, placeLoading: false
      }));
    });
  };

  const renderEmptyCart = () => (
    <EmptyCardPaper elevation={2}>
      {loading || savedCartLoading ? (
        <LoaderGrid item container>
          <CircularProgressLoader
            disableShrink
            size={100}
          />
        </LoaderGrid>
      ) : (
        <>
          <TableWrapper>
            <TableComponent aria-label="affiliates table">
              <TableHeader>
                <MainTableRow>
                  <Header> Product Item</Header>
                  <Header> Quantity</Header>
                  <Header> Unit Cost</Header>
                  <Header> Price</Header>
                  <Header>Action</Header>
                </MainTableRow>
              </TableHeader>
            </TableComponent>
          </TableWrapper>
          <TextWrapper container>
            <Grid>
              <EmptyCartImg alt="empty cart" src={EmptyCart} />
              <TextHeader>Cart is Empty</TextHeader>
            </Grid>
            <TextBody>Hi, Your cart is empty and your product will show here when you add them to cart</TextBody>
            <ShopNowButton
              onClick={() => navigate('/new-order')}
              cartIndex
            >
              Shop Now
            </ShopNowButton>
            <BulkUploadButton onClick={handleUploadDescDialog}>
              Bulk Upload
            </BulkUploadButton>
          </TextWrapper>
        </>
      )}
    </EmptyCardPaper>
  );

  const handleCloseDeleteDialog = () => {
    setState((s) => ({
      ...s,
      showDeleteDialog: false,
      toBeDeletedItem: null
    }));
  };

  const handleDeleteProduct = () => {
    handleCloseDeleteDialog();
    handleUpdateOrderProducts();
  };

  const updateCartHandler = (id, newQuantity) => {
    const request = {
      cart: [
        {
          productId: Number(id),
          quantity: Number(newQuantity),
        },
      ],
      overwriteQty: true
    };

    updateCartProduct({
      variables: { ...request },
    })
      .then(() => {
        refetchPaymentSummary();
      })
      .catch((e) => toast.error(e.message));
  };

  const handleUpdateProducts = () => {
    const differingQuantities = cart.filter((cartItem) => {
      const initiaItem = initialCartItem.find(
        (initialItem) => initialItem.id === cartItem.id
      );
      return initiaItem && initiaItem.quantity !== cartItem.quantity;
    });
    const productItems = differingQuantities.map((item) => ({ productId: Number(item.id), quantity: item.quantity }));
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    const mutationVariables = {
      cart: productItems,
      deliveryLocationId: +selectedDeliveryLocationInfo.id
    };
    if (manualCart) {
      mutationVariables.isManualOrder = true;
    }
    setState((s) => ({ ...s, updateLoading: true }));
    updateOrderProducts({
      variables: mutationVariables,
      optimisticResponse: {
        __typename: 'Mutation'
      }
    }).then(({ data }) => {
      const {
        updateOrderProducts: {
          order: {
            meta: ordermeta,
            supplierorderSet
          }
        }
      } = data;

      updateCartTotal(supplierorderSet);
      const orderMeta = parseJson(ordermeta);
      setState((s) => ({
        ...s,
        updateLoading: false,
        deliveryAddress: orderMeta.delivery_address
      }));
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleIncrementProductQuantityInCart = (product) => {
    const {
      quantity, product: { id, quantityInStock }
    } = product;

    if (+quantity >= +quantityInStock && quantityInStock !== null) return toast.error('Requested quantity not available');

    if (quantity > 0) {
      const updatedCart = cart.map((cartItem) => (cartItem.product.id === id
        ? { ...cartItem, quantity: +cartItem.quantity + 1 }
        : cartItem
      ));

      setState((s) => ({
        ...s,
        cart: updatedCart
      }));

      updateCartHandler(id, quantity + 1);
    }
  };

  const handleChangeProductQuantityInputValue = (product, value) => {
    let newValue = 0;
    if (Number(value)) newValue = value;
    else newValue = 1;

    const {
      quantity, product: { id, quantityInStock }
    } = product;

    if (newValue > quantityInStock && quantityInStock !== null) return toast.error('Requested quantity not available');
    if (quantity > 0) {
      const updatedCart = cart.map((cartItem) => (cartItem.product.id === id
        ? { ...cartItem, quantity: newValue }
        : cartItem));

      setState((s) => ({
        ...s,
        cart: updatedCart
      }));

      updateCartHandler(id, newValue);
    }
  };

  const handleDecrementProductQuantityInCart = (product) => {
    const {
      quantity, product: { id }
    } = product;

    if (quantity > 1) {
      const updatedCart = cart.map((cartItem) => (cartItem.product.id === id
        ? { ...cartItem, quantity: +cartItem.quantity - 1 }
        : cartItem));

      setState((s) => ({
        ...s,
        cart: updatedCart,
      }));

      updateCartHandler(id, quantity - 1);
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setState((s) => ({
      ...s,
      showDeleteDialog: true,
      toBeDeletedItem: id
    }));
  };

  const handleOpenSaveCartDialog = () => {
    setState((s) => ({ ...s, saveDialog: true }));
  };

  const handleCloseSaveCartDialog = () => {
    setState((s) => ({ ...s, saveDialog: false }));
  };

  const handleOpenSaveCartDialogView = () => {
    setState((s) => ({ ...s, savedCartDialog: true }));
  };

  const handleCloseSaveCartDialogView = () => {
    setState((s) => ({ ...s, savedCartDialog: false }));
  };

  const handleSave = () => {
    setState((s) => ({ ...s, cart: [] }));
    refetch();
    refetchSaveCart();
  };

  const handleOpenCartSavedDialog = () => {
    setState((s) => ({ ...s, cartSaved: true }));
  };

  const handleCloseCartSavedDialog = () => {
    setState((s) => ({ ...s, cartSaved: false }));
  };

  const handleOpenNoSavedCartDialog = () => {
    setState((s) => ({ ...s, noSavedCart: true }));
  };

  const handleCloseNoSavedCartDialog = () => {
    setState((s) => ({ ...s, noSavedCart: false }));
  };

  const handleDirectPlaceOrder = () => {
    setState((s) => ({ ...s, placeLoading: true }));
    const address = `${deliveryAddress}, ${city}, ${deliveryState} ${country}`;
    placeOrder({
      variables: { deliveryAddress: address }
    }).then(({ data: resData }) => {
      const { orderNo } = resData?.placeOrder || {};
      setPlacedOrderNo(orderNo);
      setSuccessOpenDialog(true);
      dispatch({
        type: CartActionTypes.UPDATE_CART_ITEMS,
        payload: []
      });
      setOpenPriceDisclaimer(false);
      refetch();
    }).catch((err) => { toast.error(err.message); });
  };

  const handleOpenPlaceOrderDialog = () => {
    const deliveryLocationInfo = getOutletOption();
    const { options } = deliveryLocationInfo;
    if (options.length === 0) return toast.error('Select delivery location');
    if (manualCart) {
      setState((s) => ({ ...s, placeManualOrder: true }));
    } else {
      handleDirectPlaceOrder();
    }
  };

  const handleClosePlaceOrderDialog = () => {
    setState((s) => ({ ...s, showPlaceOrderDialog: false }));
  };

  const closeDialogHandler = () => {
    setOpenOutletModal(false);
    setExistingOutlet(null);
  };

  const handleTogglePrintedManualSupplierOrderForms = (supplier) => {
    const exists = toBePrintedManualSupplierOrderForms.get(supplier);
    if (exists) {
      toBePrintedManualSupplierOrderForms.delete(supplier);
    } else {
      toBePrintedManualSupplierOrderForms.set(supplier, supplier);
    }
    setState((s) => ({ ...s, toBePrintedManualSupplierOrderForms }));
  };

  const getSelectedActionStyles = (action) => {
    const exists = extraPlaceOrderActions.get(action);
    if (!exists) {
      return { paddingTop: 20 };
    }
    return {
      borderRadius: 5,
      paddingTop: 20,
      padding: 5,
      backgroundColor: '#F4F4F4',
      opacity: 0.8,
      minWidth: 100
    };
  };

  const handleToggleExtraPlaceOrderActions = (action) => {
    const exists = extraPlaceOrderActions.get(action);
    if (exists) {
      extraPlaceOrderActions.delete(action);
    } else {
      extraPlaceOrderActions.set(action, action);
    }
    if (action === 'print') {
      setState((s) => ({
        ...s,
        printSupplierOrder: !!toBePrintedManualSupplierOrderForms.size,
        extraPlaceOrderActions
      }));
      return;
    }
    setState((s) => ({ ...s, extraPlaceOrderActions }));
  };

  const handleChangeExtraProduct = (event, index) => {
    const { name, value } = event.target;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index][name] = value;
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const handleDeleteExtraProduct = (index) => {
    const copiedExtraProducts = extraProducts.filter((product, ind) => ind !== index);
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const handleAddExtraProduct = () => {
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts.push(generateExtraProduct());
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const cancelExtraProducts = () => {
    const copiedExtraProducts = [generateExtraProduct(), generateExtraProduct(), generateExtraProduct(), generateExtraProduct()];
    toast.success('Extra products removed successfully.');
    setState((s) => ({
      ...s,
      extraProducts: copiedExtraProducts, acceptedExtraProducts: [], wantExtraProducts: false,
    }));
  };

  const saveExtraProducts = () => {
    const copiedExtraProducts = [...extraProducts];
    toast.success('Extra products successfully added.');
    setState((s) => ({
      ...s,
      extraProducts: copiedExtraProducts, acceptedExtraProducts: copiedExtraProducts
    }));
  };

  const handleWantExtraProduct = () => {
    setState((s) => ({ ...s, wantExtraProducts: !wantExtraProducts }));
  };

  const handleClearAllCart = () => {
    setState((s) => ({ ...s, cart: [] }));
    setState((s) => ({ ...s, clearCartDialog: false }));
    handleClearCart();
  };

  const renderManualModalPlaceOrderSuppliers = () => {
    const uniqueSuppliers = [...new Set([...cart.map((item) => item.supplier)])];
    return uniqueSuppliers.map((supplier) => (
      <ManualSupplierItem key={supplier} item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <ManualMethodImage src={SupplierOrderForm} />
          </Grid>
          <Grid item>{supplier?.trim().split(' ')[0]}</Grid>
          <Grid>
            <Checkbox
              checked={toBePrintedManualSupplierOrderForms.get(supplier)}
              onChange={() => handleTogglePrintedManualSupplierOrderForms(supplier)}
              color="primary"
            />
          </Grid>
        </Grid>
      </ManualSupplierItem>
    ));
  };

  const ChangeQuantity = (index, value) => {
    if (value < 1) return;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index].quantity = value;
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const changeToInt = (event, index) => {
    const { name, value } = event.target;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index][name] = parseInt(value, 10);
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  const renderExtraProductRow = (row, index) => (
    <TableRow style={{ padding: 0, borderBottom: 0 }} key={`key${index}`}>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="brandName"
            id="brandname"
            value={row.brandName}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="Name of medicine"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <ChangeOutletRow>
            <ReturnSelectField
              field={selectDosage()}
              value={row.dosageForm}
              showCheckBox={false}
              label="Select"
              handleCreditDaysOpen={() => ({})}
              fullWidth
              handleChange={(event) => handleChangeExtraProduct(event, index)}
            />
          </ChangeOutletRow>
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="strength"
            id="strength"
            value={row.strength}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="mg/iu"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="packSize"
            id="packsize"
            value={row.packSize}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="e.g 30 Tablets"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{
        borderBottom: 0, alignContent: 'center'
      }}
      >
        <Grid>
          <CartItemButtonContainer>
            <CartItemButton onClick={() => ChangeQuantity(index, row.quantity - 1)} style={{ float: 'none' }}>-</CartItemButton>
            <input
              type="number"
              name="quantity"
              id="quantity"
              min={1}
              value={row.quantity}
              onChange={(event) => changeToInt(event, index)}
              style={{
                background: '#F0F0F0', width: '40%', paddingLeft: '10px', paddingTop: '9px', paddingBottom: '15px', fontSize: '20px', border: 'none', marginTop: '5px'
              }}
            />
            <CartItemButton onClick={() => ChangeQuantity(index, row.quantity + 1)} style={{ float: 'none' }}>+</CartItemButton>
          </CartItemButtonContainer>
        </Grid>
      </TableCell>
      <TableCell style={{
        borderBottom: 0, alignContent: 'center'
      }}
      >
        <Grid>
          <Delete onClick={() => handleDeleteExtraProduct(index)} style={{ cursor: 'pointer' }} />
        </Grid>
      </TableCell>
    </TableRow>
  );

  const openPayhippo = () => {
    const orderTotal = cart.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(manualCart ? obj.orderCost : obj.unitCost)), 0) * priceFactor;
    const cartAmount = serviceFee + deliveryFee + orderTotal;
  };

  const handleOpenMCF = () => {
    setState((s) => ({ ...s, openMCF: true }));
  };

  const handleCloseDialog = () => {
    setState((s) => ({ ...s, openMCF: false }));
  };

  const renderSupplierContainers = () => (
    <SupplierCard>
      <Hidden mdDown>
        <TableWrapper>
          <TableComponent aria-label="affiliates table">
            <TableHeader>
              <MainTableRow style={{ border: 'none' }}>
                <Header>Product Name</Header>
                <Header>Quantity</Header>
                <Header>Unit Cost</Header>
                <Header>Price</Header>
                <Header>Actions</Header>
              </MainTableRow>
            </TableHeader>
            <TableBody>
              {cart.map((productItem) => (
                <ProductItem
                  key={productItem}
                  product={productItem}
                  switchOrderProductSupplier={handleSwitchOrderProductSupplier}
                  incrementProductQuantity={handleIncrementProductQuantityInCart}
                  decrementProductQuantity={handleDecrementProductQuantityInCart}
                  openDeleteDialog={handleOpenDeleteDialog}
                  closeDeleteDialog={handleCloseDeleteDialog}
                  changeInputQuantity={handleChangeProductQuantityInputValue}
                  refetch={refetch}
                />
              ))}
            </TableBody>
          </TableComponent>
        </TableWrapper>
      </Hidden>
      <Hidden mdUp>
        <Grid container>
          {cart.map((productItem) => (
            <ProductItem
              key={productItem}
              product={productItem}
              // platform={plat}
              switchOrderProductSupplier={handleSwitchOrderProductSupplier}
              incrementProductQuantity={handleIncrementProductQuantityInCart}
              decrementProductQuantity={handleDecrementProductQuantityInCart}
              openDeleteDialog={handleOpenDeleteDialog}
              closeDeleteDialog={handleCloseDeleteDialog}
              changeInputQuantity={handleChangeProductQuantityInputValue}
            />
          ))}
        </Grid>
      </Hidden>
      {/* <ProductSubTotalWrapper>
          <ProductSubTotalHeaders>
            <ProductColumnHeaderTitle style={{ width: '20%' }}>
              Subtotal
            </ProductColumnHeaderTitle>
            <ProductColumnHeaderTitle style={{ width: '25%', display: 'flex', justifyContent: 'end' }}>
              {`₦ ${currencyFormatter(supplierSubTotal)}`}
            </ProductColumnHeaderTitle>
          </ProductSubTotalHeaders>
        </ProductSubTotalWrapper> */}
    </SupplierCard>
  );
  // });

  const {
    netAmount, serviceCharge, totalAmount, deliveryFee: deliveryCost
  } = paymentSummary?.cartPaymentSummary || {};

  const InitialorderTotal = initialCartItem.reduce((sum, obj) => sum + obj.quantity * (manualCart && obj.supplier !== 'OGApharmacy' ? obj.orderCost : obj.unitCost), 0) * priceFactor;
  const currentOrderTotal = cart.reduce((sum, obj) => sum + obj.quantity * (manualCart && obj.supplier !== 'OGApharmacy' ? obj.orderCost : obj.unitCost), 0) * priceFactor;
  const orderTotal = (totalPaymentSummary - InitialorderTotal + currentOrderTotal);
  const taxTotal = business && business.tax ? orderTotal * (business.tax / 100) : 0;
  const open = Boolean(viewMoreButton);
  const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();

  return (
    <MainContent>
      <div>
        <CartGridContainer>
          <CartGridContainerHeader container sm={2} md={1}>
            <BackArrowIconContainer item sm={2} md={1} onClick={() => navigate(-1)} className="mpAdmin-uat-cartPage-back">
              <KeyboardArrowLeft />
              <ArrowBackText>Back</ArrowBackText>
            </BackArrowIconContainer>
          </CartGridContainerHeader>
          <CartItemCountContainer container xs={12} lg={8}>
            <CartItemContainer item container xs={6} lg={4}>
              <PageTitleText>Shopping Cart</PageTitleText>
            </CartItemContainer>
            <ClearBox xs={6} lg={8}>
              <CartItemCountFlex>
                <Hidden lgUp>
                  <SimpleBadge value={cartProductsCount}>
                    <CartIco style={{ fontSize: isSmall ? '5rem' : '2.05rem' }} />
                  </SimpleBadge>
                </Hidden>
                <Hidden lgDown>
                  <CartItemWrapper>
                    <CartIco style={{ fontSize: '2.05rem' }} />
                    <CartItemCountText>
                      {cartProductsCount || 0}
                        &nbsp;
                      {cartProductsCount > 1 ? 'Items' : 'Item'}
                      &nbsp;
                      in cart
                    </CartItemCountText>
                  </CartItemWrapper>
                </Hidden>
                <Hidden lgDown>
                  <Grid item>
                    <ClearAllCart
                      onClick={(e) => handleClearCartDialog(e)}
                    >
                      Clear Cart
                    </ClearAllCart>
                  </Grid>
                </Hidden>
                <Grid item>
                  <IconWrapper
                    variant="text"
                  >
                    <ActionPopper
                      handleAction={handleOpenSaveCartDialog}
                      handleActionView={handleOpenSaveCartDialogView}
                      handleCartList={handleCartList}
                      cart={cart}
                      handleNoCart={handleOpenNoSavedCartDialog}
                      cartOrder={cartOrder}
                      handleUploadDescDialog={handleUploadDescDialog}
                    />
                  </IconWrapper>
                </Grid>
              </CartItemCountFlex>
            </ClearBox>
          </CartItemCountContainer>
          <Hidden mdUp>
            <ClearAllCartMobile
              onClick={(e) => handleClearCartDialog(e)}
            >
              Clear Cart
            </ClearAllCartMobile>
          </Hidden>
          <CartContainer container xs={12}>
            <Grid container spacing={4} style={{ marginLeft: '.3rem' }}>
              <Grid xs={12} lg={8}>
                <SupplierCardPaper elevation={2}>
                  <SearchGrid item>
                    <CustomSearchField
                      name="search"
                      value={searchText}
                      fullWidth
                      placeholder="Search"
                      handleChange={(e) => handleSearch(e?.target?.value)}
                    />
                  </SearchGrid>
                  {cart.length === 0 || loading || savedCartLoading
                    ? renderEmptyCart()
                    : renderSupplierContainers() }
                  <FooterGrid item container>
                    {
                      cart.length !== 0 && (
                      <TablePagination
                        total={cartProductsCount}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        options={[10, 20, 50, 99]}
                      />
                      )
                    }
                  </FooterGrid>
                </SupplierCardPaper>
              </Grid>
              <Hidden mdDown>
                <DeliveryAddressCard2 xs={12} lg={4}>
                  <PaymentCardSection elevation={2}>
                    <PaymentSummary
                      cart={cart}
                      orderTotal={netAmount}
                      serviceFee={serviceCharge}
                      deliveryFee={deliveryCost}
                      taxTotal={taxTotal}
                      grandTotal={totalAmount}
                      handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
                      handleDirectPlaceOrder={handleDirectPlaceOrder}
                      placeLoading={placeLoading}
                      updateLoading={updateLoading}
                      platform={platform}
                      setOpenPriceDisclaimer={setOpenPriceDisclaimer}
                    />
                  </PaymentCardSection>
                </DeliveryAddressCard2>
              </Hidden>
              {cart?.length
                ? (
                  <DeliveryAddressCard md={12} lg={8}>
                    <DeliveryAddressCardSection elevation={0}>
                      <DeliveryAddressCardHeader>
                        <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                      </DeliveryAddressCardHeader>
                      <Grid style={{ marginBottom: '20px' }}>
                        <SelectOutletText>The location you want your order to be delivered to</SelectOutletText>
                        <Hidden smUp>
                          <DeliveryAddressCardHeaderSubTitle
                            onClick={handleDeliveryAddress}
                            type="button"
                            className="mpAdmin-uat-cartPage-addDeliveryLocation"
                          >
                            <DeliveryIcon />
                            Add Delivery Location
                          </DeliveryAddressCardHeaderSubTitle>
                        </Hidden>
                      </Grid>
                      {renderSelectDeliveryLocationInfo()}
                    </DeliveryAddressCardSection>
                  </DeliveryAddressCard>
                ) : ''}
              <Hidden mdUp>
                <DeliveryAddressCard2 xs={12} lg={4}>
                  <PaymentCardSection elevation={2}>
                    <PaymentSummary
                      cart={cart}
                      orderTotal={netAmount}
                      serviceFee={serviceCharge}
                      deliveryFee={deliveryCost}
                      taxTotal={taxTotal}
                      grandTotal={totalAmount}
                      handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
                      placeLoading={placeLoading}
                      updateLoading={updateLoading}
                      backRoute={backRoute}
                      handleDirectPlaceOrder={handleDirectPlaceOrder}
                      setOpenPriceDisclaimer={setOpenPriceDisclaimer}
                    />
                  </PaymentCardSection>
                </DeliveryAddressCard2>
              </Hidden>
            </Grid>
          </CartContainer>
          {canUseMastersList && (
            <Grid>
              <div>
                <h3 style={{ marginBottom: ' 10px' }}>
                  Want other products not on our list?
                  <Span
                    onClick={handleWantExtraProduct}
                    style={{
                      paddingLeft: '20px', marginTop: '90px', color: '#A3A3A3', width: '80%', cursor: 'pointer'
                    }}
                  >
                    <u>Click here</u>
                  </Span>
                </h3>
              </div>
              { wantExtraProducts && (
                <AddProductCard>
                  <AddProductCardSection>
                    <TableContainerWrapper>
                      <TableComponent aria-label="additional products">
                        <MainTableHead>
                          <TableRow>
                            <HeaderCell style={{ margin: 0, borderBottom: 0 }}>Product Name</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0 }}>Dosage Form</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0 }}>Strength</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0 }}>Pack Size</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0, align: 'center' }}>Qty</HeaderCell>
                            <HeaderCell style={{ borderBottom: 0, align: 'center' }} />
                          </TableRow>
                        </MainTableHead>
                        <TableBody>
                          {extraProducts.map((row, index) => renderExtraProductRow(row, index))}
                        </TableBody>
                      </TableComponent>
                    </TableContainerWrapper>
                    <Span
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => ({})}
                      onClick={handleAddExtraProduct}
                      style={{
                        paddingLeft: '20px', marginTop: '90px', color: '#A3A3A3', width: '80%', cursor: 'pointer'
                      }}
                    >
                      <u>Add Product</u>
                    </Span>
                    {' '}
                    <CheckCircleOutlineRoundedIcon
                      onClick={saveExtraProducts}
                      style={{
                        float: 'right', cursor: 'pointer', paddingLeft: '30px', fontSize: '35px', width: '5%'
                      }}
                    />
                    <HighlightOffRoundedIcon
                      onClick={cancelExtraProducts}
                      style={{
                        float: 'right', cursor: 'pointer', paddingLeft: '30px', fontSize: '35px', width: '5%',
                      }}
                    />
                  </AddProductCardSection>
                </AddProductCard>
              )}
            </Grid>
          )}
          {platform !== 'erp' ? (
            <SimilarProducts
              cart
              refetchCart={refetch}
            />
          ) : ''}
        </CartGridContainer>
        <CreateOutletModal
          cart
          state={state}
          setState={setState}
          dialogOpen={openOutletModal}
          existingOutlet={existingOutlet}
          closeDialog={closeDialogHandler}
        />

        <Dialog
          open={showDeleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <TitleText>Delete Product</TitleText>
          </DialogTitle>
          <DialogContent>
            <ContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this product from the cart?
            </ContentText>
          </DialogContent>
          <DialogActions>
            <DialogCancelButton
              onClick={handleCloseDeleteDialog}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>

            <DialogOkButton
              onClick={() => handleDeleteProduct()}
            >
              <DialogActionButtonText>
                OK
              </DialogActionButtonText>
            </DialogOkButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showPlaceOrderDialog}
          TransitionComponent={Transition}
          fullWidth
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <TitleText>Place Order</TitleText>
          </DialogTitle>
          <DialogContent>
            <ContentText id="alert-dialog-slide-description">
              Are you sure you want to place the order for the current products in the cart?
            </ContentText>
          </DialogContent>
          <DialogActions>
            <DialogCancelButton
              onClick={handleClosePlaceOrderDialog}
              disabled={placeLoading}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>
            <DialogOkButton
              onClick={handlePlaceOrderProducts}
              disabled={placeLoading}
            >
              {placeLoading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                <DialogActionButtonText>
                  OK
                </DialogActionButtonText>
              )}
            </DialogOkButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={placeManualOrder}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <ManualDialogPadder>
            <ManualPlaceOrder id="alert-dialog-slide-title">Place order</ManualPlaceOrder>
            <Grid style={{ marginTop: '-10px' }}>
              Choose how you to want to send your order to suppliers
            </Grid>
          </ManualDialogPadder>

          <br />
          <Grid style={{ backgroundColor: '#F4F4F4' }}>
            <ManualDialogPadder container>
              {renderManualModalPlaceOrderSuppliers()}
            </ManualDialogPadder>
          </Grid>

          <ManualDialogPadder>
            <Grid container justifyContent="space-between">
              <Grid style={{ ...getSelectedActionStyles('pdf') }}>
                <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('pdf')} justifyContent="center" alignItems="center">
                  <ManualMethodImage src={Pdf} />
                  <OptionText>Save as PDF</OptionText>
                </Grid>
              </Grid>
              <Grid item style={{ ...getSelectedActionStyles('email') }}>
                <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('email')} justifyContent="center" alignItems="center">
                  <ManualMethodImage src={Email} />
                  <OptionText>Email</OptionText>
                </Grid>
              </Grid>
              <Grid item style={{ ...getSelectedActionStyles('print') }}>
                <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('print')} justifyContent="center" alignItems="center">
                  <Grid style={{ minHeight: 75 }}><ManualMethodImage src={Print} /></Grid>
                  <OptionText>Print</OptionText>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container justifyContent="center">
              <ManualDialogCancelButton onClick={() => setState((s) => ({ ...s, placeManualOrder: false }))}>
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </ManualDialogCancelButton>
              <ManualDialogDoneButton
                // disabled={!selectedAffiliate}
                onClick={handleDirectPlaceOrder}
              >
                <DialogActionButtonText>
                  Done
                </DialogActionButtonText>
              </ManualDialogDoneButton>
            </Grid>
            <br />
          </ManualDialogPadder>
        </Dialog>
        <Dialog
          open={printSupplierOrder}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <SupplierOrderPrint
            printSupplierOrder={printSupplierOrder}
            orderId={orderId}
            cart={cart}
            handleSupplierOrderPrint={() => setState((s) => ({ ...s, printSupplierOrder: false }))}
            selectedDeliveryLocationInfo={getSelectedDeliveryLocationInfo()}
            toBePrintedManualSupplierOrderForms={toBePrintedManualSupplierOrderForms}
          />
        </Dialog>
        <RenderPopper
          open={open}
          anchorEl={viewMoreButton}
          onClickAway={() => handleOpenViewModal()}
          popperPlacement="right"
          modifiers={{
            offset: {
              enabled: true,
              offset: '0vw, 2vh',
            }
          }}
        >
          <GridWrapper container item>
            <EditContainer container item>
              <ItemWrapper onClick={() => handleEditDeliveryLocation(selectedDeliveryLocationInfo)}>
                <ItemTypo variant="subtitle1">
                  Edit Address
                </ItemTypo>
              </ItemWrapper>
            </EditContainer>

            <GridContainer container item>
              <ItemWrapper disabled onClick={(e) => handleDeleteAddressDialog(e)}>
                <ItemTypo variant="subtitle1">
                  Delete Address
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>

          </GridWrapper>
        </RenderPopper>
        {showLoan && (
          <Floater
            state={state}
            priceFactor={priceFactor}
            openPayhippo={openPayhippo}
            handleOpenMCF={handleOpenMCF}
            handleCloseDialog={handleCloseDialog}
          />
        )}
        <ConfirmDialog
          handleClose={handleDeleteAddressDialog}
          deleteAddressDialog={deleteAddressDialog}
          selectedDeliveryLocationInfo={selectedDeliveryLocationInfo}
          handleDelete={handleDeleteDeliveryLocation}
        />
        <ClearCartDialog
          handleClose={handleClearCartDialog}
          clearCartDialog={clearCartDialog}
          handleDelete={handleClearAllCart}
        />
        <CartProcessingModal
          orderProcessing={orderProcessingDialog}
          handleClose={handleOrderProcessingDialog}
          message={modalMessage}
        />
        {/* <ReportExportModel
          open={openExportModel}
          handleClose={handleCloseExportDialog}
          endpoint="cart"
          params={_params}
        /> */}
        <SaveDialog
          saveDialog={saveDialog}
          handleClose={handleCloseSaveCartDialog}
          handleSave={handleSave}
          handleAction={handleOpenCartSavedDialog}
        />
        <CartSaved
          cartSaved={cartSaved}
          handleClose={handleCloseCartSavedDialog}
        />
        <SavedCartDialog
          savedCartDialog={savedCartDialog}
          handleClose={handleCloseSaveCartDialogView}
          cartOrder={cartOrder}
          refetch={refetch}
        />
        <NoSavedCart
          noSavedCart={noSavedCart}
          handleClose={handleCloseNoSavedCartDialog}
        />
        <SuccessDialog
          openDialog={successOpenDialog}
          setOpenDialog={setSuccessOpenDialog}
          title="Thank You!"
          desc={`We sincerely appreciate your trust in OGApharmacy for your pharmaceutical needs. Your order #${placedOrderNo} has been received and is now being processed.`}
          option="ok"
        />

        <PriceDisclaimer
          openDialog={openPriceDisclaimer}
          setOpenDialog={setOpenPriceDisclaimer}
          placeOrder={handleDirectPlaceOrder}
          loading={placeOrderLoading}
        />

        <UploadFlow openDialog={uploadDescDialog} toggleHandler={handleUploadDescDialog} handleUploadModal={handleCsvDialog} />
        <MapOrderCsvDialog
          openProdDialog={uploadCsvDialog}
          toggleHandler={handleCsvDialog}
        />
      </div>
    </MainContent>
  );
};

CartIndex.propTypes = {
  manualCart: PropTypes.bool,
};

CartIndex.defaultProps = {
  manualCart: false,
};

export default CartIndex;
