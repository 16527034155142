import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Grid, TableBody
} from '@mui/material';
import moment from 'moment';
import currencyFormatter from '../../../../shared/currencyFormatter';
import {
  PageTitleContainer, TableHeader, MainTableRow,
} from '../../../../cart/cart.styles';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, PageTitleText, PageTitleSubText,
  SubTotalText, SubTotalPrice, SubTotalBox, TableWrapper, TableComponent, Header,
  BackText, OrderDetailCard, OrderDetailWrapper, ImgContent, CardLogo, OrderDetailInfoWrapper, TitleText,
  SubText, SupplierDetailCardHeader, SupplierDetailCardTitle, OrderDeliveryDetailInfoWrapperRow, OrderDeliveryInfoLabel, OrderDeliveryInfoText, CartGridContainer,
  OrderStatus, KeyboardArrowLeft, DeliveryColor, BackArrowIconContainer, CartGridContainerHeader, GrandTotalBox, GrandTotaltext
} from './styles';
import { GET_ORDER_DETAILS_ORDER } from '../../../../../queries/orders';
import MainContent from '../../../../customComponents/mainContent';
import SupplierOrderDetailProductItem from './product-item';
import ActionButtonsPopper from './actionPopper';

import { parseJson } from '../../../../affiliates/utils';

const initialState = {
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  totalPromo: 0,
  showCancelOrderDialog: false,
  editNote: false,
  note: '',
  orderId: null,
  supplierPaymentTerm: null,
  orderMeta: null,
  showPaymentDetails: false,
  orderStatus: null,
  deliveryId: null,
  business: {},
  products: [],
  deliveryFee: 0
};
const OrderDetailsContainer = () => {
  const [state, setState] = useState(initialState);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const itemNo = location?.state;

  const { data } = useQuery(GET_ORDER_DETAILS_ORDER, {
    variables: { id: +params.id, businessId: +itemNo.businessId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && data?.businessOrder) {
      const {
        businessOrder: {
          id: orderId,
          refId,
          total,
          business,
          status,
          meta: deliveryMeta,
          order: {
            dateCreated,
            paymentSummary,
            meta,
            business: buyerInfo,
            id: parentOrderId
          }
        }
      } = data;

      const datePlaced = moment(dateCreated).format('MMMM DD, YYYY [at] h:mma');
      const {
        sellerContact, location: sellerLocation, name: sellersName, businessuserSet
      } = business;
      const { buyerContact, name: buyersName } = buyerInfo;
      const sellerDeliveryFee = parseJson(deliveryMeta);
      const parseSummary = parseJson(paymentSummary);
      const parseMeta = parseJson(meta);
      const serviceCharge = parseSummary?.service_charge ?? 0;
      const deliveryFee = sellerDeliveryFee?.delivery_fee?.amount ?? 0;

      setState((s) => ({
        ...s,
        orderId,
        refId,
        sellerContact,
        dateCreated: datePlaced,
        business,
        serviceCharge,
        paymentSummary: parseSummary,
        products: data?.orderProducts || [],
        status,
        total,
        sellerLocation,
        parseMeta,
        buyerContact,
        buyersName,
        sellersName,
        parentOrderId,
        userId: businessuserSet[0]?.user?.id,
        deliveryFee
      }));
    }
  }, [data]);

  const {
    sellerContact, dateCreated, products, parseMeta, status, total, sellerLocation, buyersName, buyerContact, refId, sellersName, deliveryFee
  } = state;

  const {
    delivery_address: deliveryAddress
  } = parseMeta || {};

  const getColorGrid = (color, bkg, name) => (
    <OrderStatus item container className={`mpAdmin-uat-ordersPage-${name}`}>
      <DeliveryColor color={bkg} />
      {name}
    </OrderStatus>
  );

  const getStatus = () => {
    switch (status) {
      case 'ORDER_PLACED':
        return getColorGrid('#07DE10', '#00B588', 'Order placed');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#00BFFF', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#00BFFF', 'Ready for Dispatch');
      case 'ORDER_REJECTED':
        return getColorGrid('#606060', '#FF0F0F', 'Rejected');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#F29053', 'Order Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#F29053', 'Order sent');
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#F29053', 'Completed');
      case 'ORDER_AWAITING_APPROVAL':
        return getColorGrid('#606060', '#F29053', `${itemNo.index ? 'Order Placed' : 'Awaiting Approval'}`);
      case 'CLOSED':
        return getColorGrid('#07DE10', '#00B588', 'Completed');
      default:
        return getColorGrid('#606060', '#00B588', `${itemNo.index ? 'Order Approved' : 'Order Received'}`);
    }
  };

  return (
    <MainContent>
      <CartGridContainer>
        <CartGridContainerHeader container>
          <Grid xs={12}>
            <BackArrowIconContainer onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
              <BackText>Back</BackText>
            </BackArrowIconContainer>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <PageTitleContainer>
              <PageTitleText>
                Order ID &nbsp;
                {refId}
              </PageTitleText>
              <PageTitleSubText>
                Order Package &nbsp;
                {itemNo.index ? itemNo.index : ''}
              </PageTitleSubText>
            </PageTitleContainer>
          </Grid>
          {itemNo?.isSeller ? (
            <Grid>
              <ActionButtonsPopper state={state} businessId={+itemNo.businessId} />
            </Grid>
          ) : ''}
        </CartGridContainerHeader>

        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item container lg={4}>
            <OrderDetailCard>
              <OrderDetailWrapper container>
                <ImgContent>
                  <CardLogo />
                </ImgContent>
              </OrderDetailWrapper>
              <Grid item>{getStatus()}</Grid>
              <OrderDetailInfoWrapper>
                <TitleText>
                  Order ID: &nbsp; &nbsp;
                  <SubText>
                    {`#${refId}`}
                  </SubText>
                </TitleText>
                <TitleText>
                  Date Placed: &nbsp; &nbsp;
                  <SubText>
                    {`${dateCreated || 'May 15, 2024 at 12:30 pm'}`}
                  </SubText>
                </TitleText>
              </OrderDetailInfoWrapper>
            </OrderDetailCard>
          </Grid>

          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard elevation={0}>
                <>
                  <SupplierDetailCardHeader>
                    <SupplierDetailCardTitle>Delivery Location</SupplierDetailCardTitle>
                  </SupplierDetailCardHeader>
                  <OrderDeliveryDetailInfoWrapperRow>
                    <Grid>
                      <OrderDeliveryInfoLabel>Buyer’s Name</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ buyersName || 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                    <Grid>
                      <OrderDeliveryInfoLabel>Mobile</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ buyerContact && buyerContact.phoneNumber ? buyerContact.phoneNumber : 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                    <Grid>
                      <OrderDeliveryInfoLabel>Email Address</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ buyerContact && buyerContact.emailAddress ? buyerContact.emailAddress : 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                    <Grid>
                      <OrderDeliveryInfoLabel>Location</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ deliveryAddress ? `${deliveryAddress}` : 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                  </OrderDeliveryDetailInfoWrapperRow>
                </>
              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>

          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard elevation={0}>
                <>
                  <SupplierDetailCardHeader>
                    <SupplierDetailCardTitle>Seller’s Details</SupplierDetailCardTitle>
                  </SupplierDetailCardHeader>
                  <OrderDeliveryDetailInfoWrapperRow>
                    <Grid>
                      <OrderDeliveryInfoLabel>Seller’s Name</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ sellerContact && sellersName ? sellersName : 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                    <Grid>
                      <OrderDeliveryInfoLabel>Mobile</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ sellerContact && sellerContact.phoneNumber ? sellerContact.phoneNumber : 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                    <Grid>
                      <OrderDeliveryInfoLabel>Location</OrderDeliveryInfoLabel>
                      <OrderDeliveryInfoText>{ sellerContact ? `${sellerContact.storeAddress}, ${sellerContact.city}, ${sellerLocation}` : 'N/A' }</OrderDeliveryInfoText>
                    </Grid>
                  </OrderDeliveryDetailInfoWrapperRow>
                </>
              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item container lg={12}>
            <SupplierDetailCardWrapper elevation={2}>
              <TableWrapper>
                <TableComponent aria-label="affiliates table">
                  <TableHeader>
                    <MainTableRow>
                      <Header style={{ paddingLeft: '1rem' }}> Product name</Header>
                      <Header> Qty Received</Header>
                      <Header> Qty Ordered</Header>
                      <Header> Unit Cost</Header>
                      <Header>Price</Header>
                    </MainTableRow>
                  </TableHeader>
                  <TableBody>
                    {products.map((productItem) => (
                      <SupplierOrderDetailProductItem
                        key={productItem}
                        product={productItem}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              </TableWrapper>
              <SubTotalBox>
                <SubTotalText>
                  Subtotal
                </SubTotalText>
                <SubTotalPrice>
                  {`₦${currencyFormatter(total)}`}
                </SubTotalPrice>
              </SubTotalBox>
              <SubTotalBox>
                <SubTotalText>
                  Delivery Fee
                </SubTotalText>
                <SubTotalPrice>
                  {`₦${currencyFormatter(Number(deliveryFee))}`}
                </SubTotalPrice>
              </SubTotalBox>
              <GrandTotalBox>
                <GrandTotaltext>
                  Grand Total
                </GrandTotaltext>
                <SubTotalPrice style={{ fontWeight: 'bold' }}>
                  {`₦${currencyFormatter(Number(total) + Number(deliveryFee))}`}
                </SubTotalPrice>
              </GrandTotalBox>
            </SupplierDetailCardWrapper>
          </Grid>
        </Grid>
      </CartGridContainer>
    </MainContent>
  );
};

export default OrderDetailsContainer;
