import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Grid, useMediaQuery, Hidden,
  Box
} from '@mui/material';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer,
  ActionsContainer, HeaderText, TypoContainer,
  PricingAndSaving, PricingWrapper, Pricing, ActionButton, AddButton,
  CircularProgressLoader, ActionTextField, InputButtonGroup,
  AddCartGrid, ShoppingCartImg, AddCartTypo, ChangeWrapper, MinusIcon, PlusIcon,
  CartItemIcon, CardWrapper, SubscribeButton, PackSizeTypoText,
  DividerLine, ManufacTypo, ProductVContainer, DivBullet, ProductVariantText,
  AddCartListGrid,
  DeliveryContainer
} from './productCard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { ADD_TO_CART_MUTATION } from '../../../mutations/orders';
import { useStateValue } from '../../../providers/stateProvider';
import { CostDeliveryIcon, FreeDeliveryIcon } from '../../../assets/svgs';

const ProductCard = ({
  product, cart: cartProduct, similarAction, index, refetchCart, view
}) => {
  const [{
    cart: { productCartItems }, user: { session: { businessType } },
  }, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const {
    id, brandName, expiryDate, meta, marketSellingPrice, business, quantityInStock, manufacturer, deliveryInfo
  } = product;
  const isWholesaler = businessType === 'WHOLESALER';
  const obj = meta ? JSON.parse(meta) : {};
  const { pack_size: packSize, image, product_variant: prodVariant } = obj;
  const { name: businessName } = business || {};

  const [counter, setCounter] = useState(0);
  const [cartItemCount, setCartItemCount] = useState(0);
  const batchExpiryDate = expiryDate ? moment(expiryDate).format('MM/YY') : 'N/A';

  const qtyNotInStock = isWholesaler && prodVariant !== 'AVAILABLE' && (quantityInStock <= 0 || quantityInStock === null);
  const notAvailable = prodVariant === 'NOT_AVAILABLE';

  const [updateOrder, { loading }] = useMutation(ADD_TO_CART_MUTATION);

  const nonUpdateProduct = !isWholesaler && (prodVariant !== 'NOT_AVAILABLE' && prodVariant !== 'AVAILABLE');

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (isWholesaler && prodVariant !== 'AVAILABLE' && quantityInStock !== null && sum > quantityInStock) {
      return toast.error('Requested quantity not available');
    }
    setCartItemCount(0);
    setCounter(sum);
  };
  const handleChange = (value) => {
    if (isWholesaler && prodVariant !== 'AVAILABLE' && quantityInStock !== null && value > quantityInStock) {
      return toast.error('Requested quantity not available');
    }
    setCounter(value);
    setCartItemCount(0);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  useEffect(() => {
    if (productCartItems) {
      const filteredProducts = productCartItems.filter((item) => Number(item.product.id) === Number(id));
      setCartItemCount(filteredProducts[0]?.quantity);
    }
  }, [productCartItems]);

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        cart: [
          { productId: Number(id), quantity: counter }
        ],
        overwriteQty: true
      }
    })
      .then(({ data }) => {
        // setCounter(0);
        const { message, order: { orderProducts } } = data?.updateCart || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: orderProducts?.length
        });
        dispatch({
          type: CartActionTypes.UPDATE_CART_ITEMS,
          payload: orderProducts
        });
        if (cartProduct) {
          refetchCart();
        }
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleClick = () => {
    navigate(`/new-order/${id}/details`);
  };

  const stat = deliveryInfo === 'Free Delivery' ? 'free' : 'charge';
  const renderDeliveryStatus = () => (
    <DeliveryContainer status={stat}>
      {stat === 'free' ? <FreeDeliveryIcon style={isSmall ? { width: '3rem' } : {}} /> : <CostDeliveryIcon /> }
      {stat === 'free' ? 'Free Delivery' : 'Delivery Fee Applies' }
    </DeliveryContainer>
  );

  const renderGridView = () => (
    <CardPaper
      similarAction={similarAction}
      elevation={2}
      spacing={3}
      className={`mpFe-uat-new-order-card-${index}`}
    >
      <CardWrapper>
        {!isWholesaler ? (
          <>
            {(!notAvailable && !nonUpdateProduct) && (
            <ProductVContainer cart={cartProduct} status="available">
              <DivBullet type="available" />
                &nbsp;
              <ProductVariantText status="available">Available</ProductVariantText>
            </ProductVContainer>
            )}
            {(notAvailable || nonUpdateProduct) && (
            <ProductVContainer cart={cartProduct} status="stockOut">
              <DivBullet type="stockOut" />
                &nbsp;
              <ProductVariantText status="stockOut">Not Available</ProductVariantText>
            </ProductVContainer>
            )}
          </>
        ) : (
          qtyNotInStock
            && (
              <ProductVContainer cart={cartProduct} status="stockOut">
                <DivBullet type="stockOut" />
                &nbsp;
                <ProductVariantText status="stockOut">Out of Stock</ProductVariantText>
              </ProductVContainer>
            )
        )}
        <ImgBox onClick={handleClick}>
          <ProductImg component="img" src={image || 'https://res.cloudinary.com/health-id/image/upload/v1594134267/Placeholders/Product_Placeholder.png'} alt={brandName} cart />
        </ImgBox>
        <DetailsBox>
          {renderDeliveryStatus()}
          <HeaderContainer>
            <HeaderText variant="subtitle2">
              {brandName}
            </HeaderText>
          </HeaderContainer>
          <Hidden mdUp>
            <TypoContainer item container xs={12}>
              {isWholesaler ? (
                <ChangeWrapper container item xs={12}>
                  <PackSizeTypoText item xs={12}>
                    Seller :
                    &nbsp;
                    {businessName}
                  </PackSizeTypoText>
                </ChangeWrapper>
              ) : (
                <ChangeWrapper container item xs={12}>
                  <ManufacTypo>
                    {manufacturer ?? 'N/A'}
                  </ManufacTypo>
                </ChangeWrapper>
              )}
              <ChangeWrapper container item xs={12}>
                {quantityInStock === null ? (
                  <PackSizeTypoText item xs={12}>
                    Pack Size:
                    &nbsp; &nbsp;
                    {packSize}
                  </PackSizeTypoText>
                ) : (
                  <PackSizeTypoText item xs={12}>
                    {(quantityInStock === 0 && prodVariant === 'AVAILABLE') ? (
                      <>
                        Pack Size:
                        &nbsp; &nbsp;
                        {packSize}
                      </>
                    ) : (
                      <>
                        {packSize}
                      &nbsp;
                      </>
                    )}

                    {isWholesaler && (
                    <>
                      {!(quantityInStock === 0 && prodVariant === 'AVAILABLE') && <DividerLine />}
                      {quantityInStock ? (
                        `${quantityInStock.toLocaleString()} in stock`
                      ) : (
                        prodVariant !== 'AVAILABLE' && 'Out of stock'
                      )}
                    </>
                    )}
                  </PackSizeTypoText>
                )}
              </ChangeWrapper>
              {isWholesaler ? (
                <ChangeWrapper>
                  <PackSizeTypoText>
                    Expiry Date:
                    &nbsp; &nbsp;
                    {batchExpiryDate}
                  </PackSizeTypoText>
                </ChangeWrapper>
              ) : ''}
              <PricingAndSaving item container justify-content="space-between">
                <PricingWrapper item xs={12}>
                  <Pricing>
                    {currencyFormatter(marketSellingPrice)}
                  </Pricing>
                </PricingWrapper>
              </PricingAndSaving>
            </TypoContainer>
          </Hidden>
        </DetailsBox>
      </CardWrapper>
      <ActionsContainer container spacing={isSmall ? 4 : ''}>
        <Hidden mdDown>
          <TypoContainer item container xs={12}>
            {isWholesaler ? (
              <ChangeWrapper container item xs={12}>
                <PackSizeTypoText item xs={12}>
                  Seller :
                  &nbsp;
                  {businessName}
                </PackSizeTypoText>
              </ChangeWrapper>
            ) : (
              <ChangeWrapper container item xs={12}>
                <ManufacTypo>
                  {manufacturer}
                </ManufacTypo>
              </ChangeWrapper>
            )}
            <ChangeWrapper container item xs={12}>
              {quantityInStock === null ? (
                <PackSizeTypoText item xs={12}>
                  Pack Size:
                  &nbsp; &nbsp;
                  {packSize}
                </PackSizeTypoText>
              ) : (
                <PackSizeTypoText item xs={12}>
                  {(quantityInStock === 0 && prodVariant === 'AVAILABLE') ? (
                    <>
                      Pack Size:
                      &nbsp; &nbsp;
                      {packSize}
                    </>
                  ) : (
                    packSize
                  )}

                  {isWholesaler && (
                  <>
                    {!(quantityInStock === 0 && prodVariant === 'AVAILABLE') && <DividerLine />}
                    {quantityInStock ? (
                      `${quantityInStock.toLocaleString()} in stock`
                    ) : (
                      prodVariant !== 'AVAILABLE' && 'Out of stock'
                    )}
                  </>
                  )}
                </PackSizeTypoText>
              )}
            </ChangeWrapper>
            {isWholesaler ? (
              <ChangeWrapper>
                <PackSizeTypoText>
                  Expiry Date:
                  &nbsp; &nbsp;
                  {batchExpiryDate}
                </PackSizeTypoText>
              </ChangeWrapper>
            ) : ''}
            <PricingAndSaving item container justify-content="space-between">
              <PricingWrapper item xs={12}>
                <Pricing>
                  {currencyFormatter(marketSellingPrice)}
                </Pricing>
              </PricingWrapper>
            </PricingAndSaving>
          </TypoContainer>
        </Hidden>
        <AddCartGrid item xs={12} md={12} lg={12}>
          <InputButtonGroup size="small" aria-label="small button group">
            <ActionButton
              onClick={() => handleActionButtons(-1)}
              disabled={counter === 0 || notAvailable || qtyNotInStock || nonUpdateProduct}
              className={`mpFe-uat-new-order-minus-${index}`}
              style={{ justifyContent: 'flex-start' }}
              data-testid="decrement"
            >
              <MinusIcon />
            </ActionButton>
            <ActionTextField
              InputProps={{ disableUnderline: true, }}
              variant="standard"
              placeholder={0}
              value={counter || ''}
              onChange={(e) => handleChange(Number(e.target.value))}
              disabled={notAvailable || qtyNotInStock || nonUpdateProduct}
            />
            <ActionButton
              onClick={() => handleActionButtons(1)}
              className={`mpFe-uat-new-order-add-${index}`}
              style={{ justifyContent: 'flex-end' }}
              data-testid="increment"
              disabled={notAvailable || qtyNotInStock || nonUpdateProduct}
            >
              <PlusIcon />
            </ActionButton>
          </InputButtonGroup>
        </AddCartGrid>
        <Grid container item xs={12} md={12}>
          {!cartItemCount ? (
            <AddCartGrid item xs={12} style={{ marginTop: '3px' }}>
              <AddButton
                startIcon={<ShoppingCartImg outOfStock={qtyNotInStock || notAvailable || nonUpdateProduct} />}
                variant="outlined"
                disableElevation
                onClick={handleAddToCart}
                disabled={counter === 0 || qtyNotInStock || notAvailable}
                counter={counter}
                outOfStock={qtyNotInStock || notAvailable || nonUpdateProduct}
                className={`mpFe-uat-new-order-plus-${index}`}
                data-testid="add-to-cart"
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={24}
                    thickness={4}
                  />
                )
                  : (
                    <AddCartTypo type={similarAction && 'similarAction'}>
                      &nbsp;
                      Add To Cart
                    </AddCartTypo>
                  )}
              </AddButton>
            </AddCartGrid>
          ) : (
            <AddCartGrid item xs={12} style={{ marginTop: '3px' }}>
              <SubscribeButton
                startIcon={<CartItemIcon />}
                variant="outlined"
                disableElevation
                counter={counter}
                className={`mpFe-uat-new-order-subscription-${index}`}
                data-testid="items-number"
              >
                <AddCartTypo type="notify">
                  {cartItemCount}
                &nbsp;
                  { cartItemCount > 1 ? 'items' : 'item' }
                  &nbsp;
                  in cart
                </AddCartTypo>
              </SubscribeButton>
            </AddCartGrid>
          )}
        </Grid>
      </ActionsContainer>
    </CardPaper>
  );

  const renderListView = () => (
    <CardPaper
      similarAction={similarAction}
      elevation={2}
      spacing={3}
      className={`mpFe-uat-new-order-card-${index}`}
      style={{ padding: '2rem' }}
    >
      <CardWrapper>
        <DetailsBox>
          <HeaderContainer container justifyContent="space-between" alignItems="center">
            <Grid item>
              {renderDeliveryStatus()}
              <HeaderText variant="subtitle2" onClick={handleClick}>
                {brandName}
              </HeaderText>
            </Grid>
            <PricingAndSaving item>
              <Pricing>
                {currencyFormatter(marketSellingPrice)}
              </Pricing>
            </PricingAndSaving>
          </HeaderContainer>
          <Hidden mdUp>
            <TypoContainer item container xs={12}>
              {isWholesaler ? (
                <ChangeWrapper container item xs={12}>
                  <PackSizeTypoText item xs={12}>
                    Seller :
                    &nbsp;
                    {businessName}
                  </PackSizeTypoText>
                </ChangeWrapper>
              ) : (
                <ChangeWrapper container item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <ManufacTypo style={{ marginBottom: '0.5rem', borderRight: '1px solid #ccc', paddingRight: '2rem' }}>
                      {manufacturer ?? 'N/A'}
                    </ManufacTypo>
                    {!isWholesaler ? (
                      <>
                        {(!notAvailable && !nonUpdateProduct) && (
                        <ProductVContainer cart={cartProduct} status="available">
                          <DivBullet type="available" />
                &nbsp;
                          <ProductVariantText status="available">Available</ProductVariantText>
                        </ProductVContainer>
                        )}
                        {(notAvailable || nonUpdateProduct) && (
                        <ProductVContainer cart={cartProduct} status="stockOut">
                          <DivBullet type="stockOut" />
                &nbsp;
                          <ProductVariantText status="stockOut">Not Available</ProductVariantText>
                        </ProductVContainer>
                        )}
                      </>

                    ) : (
                      qtyNotInStock
            && (
              <ProductVContainer cart={cartProduct} status="stockOut">
                <DivBullet type="stockOut" />
                &nbsp;
                <ProductVariantText status="stockOut">Out of Stock</ProductVariantText>
              </ProductVContainer>
            )
                    )}
                  </Box>
                </ChangeWrapper>
              )}
              <ChangeWrapper container item xs={12}>
                {quantityInStock === null ? (
                  <PackSizeTypoText item xs={12}>
                    Pack Size:
                    &nbsp; &nbsp;
                    {packSize}
                  </PackSizeTypoText>
                ) : (
                  <PackSizeTypoText item xs={12}>
                    {(quantityInStock === 0 && prodVariant === 'AVAILABLE') ? (
                      <>
                        Pack Size:
                        &nbsp; &nbsp;
                        {packSize}
                      </>
                    ) : (
                      <>
                        {packSize}
                        &nbsp;
                      </>
                    )}

                    {isWholesaler && (
                      <>
                        {!(quantityInStock === 0 && prodVariant === 'AVAILABLE') && <DividerLine />}
                        {quantityInStock ? (
                          `${quantityInStock.toLocaleString()} in stock`
                        ) : (
                          prodVariant !== 'AVAILABLE' && 'Out of stock'
                        )}
                      </>
                    )}
                  </PackSizeTypoText>
                )}
              </ChangeWrapper>
              {isWholesaler ? (
                <ChangeWrapper>
                  <PackSizeTypoText>
                    Expiry Date:
                    &nbsp; &nbsp;
                    {batchExpiryDate}
                  </PackSizeTypoText>
                </ChangeWrapper>
              ) : ''}
            </TypoContainer>
          </Hidden>
        </DetailsBox>
      </CardWrapper>
      <ActionsContainer container spacing={isSmall ? 4 : ''}>
        <AddCartListGrid container xs={12} md={12} lg={12} justifyContent="space-between" alignItems="center">
          <Grid item xs={5} md={12}>
            <InputButtonGroup size="small" aria-label="small button group">
              <ActionButton
                onClick={() => handleActionButtons(-1)}
                disabled={counter === 0 || notAvailable || qtyNotInStock || nonUpdateProduct}
                className={`mpFe-uat-new-order-minus-${index}`}
                style={{ justifyContent: 'flex-start' }}
                data-testid="decrement"
              >
                <MinusIcon />
              </ActionButton>
              <ActionTextField
                InputProps={{ disableUnderline: true, }}
                variant="standard"
                placeholder={0}
                value={counter || ''}
                onChange={(e) => handleChange(Number(e.target.value))}
                disabled={notAvailable || qtyNotInStock || nonUpdateProduct}
              />
              <ActionButton
                onClick={() => handleActionButtons(1)}
                className={`mpFe-uat-new-order-add-${index}`}
                style={{ justifyContent: 'flex-end' }}
                data-testid="increment"
                disabled={notAvailable || qtyNotInStock || nonUpdateProduct}
              >
                <PlusIcon />
              </ActionButton>
            </InputButtonGroup>
          </Grid>
          <Grid item xs={5} md={12}>
            {!cartItemCount ? (
              <AddCartGrid item xs={12} style={{ marginTop: '3px' }}>
                <AddButton
                  startIcon={<ShoppingCartImg outOfStock={qtyNotInStock || notAvailable || nonUpdateProduct} />}
                  variant="outlined"
                  disableElevation
                  onClick={handleAddToCart}
                  disabled={counter === 0 || qtyNotInStock || notAvailable}
                  counter={counter}
                  outOfStock={qtyNotInStock || notAvailable || nonUpdateProduct}
                  className={`mpFe-uat-new-order-plus-${index}`}
                  data-testid="add-to-cart"
                  style={{ minHeight: '8rem !important' }}
                >
                  {loading ? (
                    <CircularProgressLoader
                      disableShrink
                      size={24}
                      thickness={4}
                    />
                  )
                    : (
                      <AddCartTypo type={similarAction && 'similarAction'}>
                        &nbsp;
                        Cart
                      </AddCartTypo>
                    )}
                </AddButton>
              </AddCartGrid>
            ) : (
              <AddCartGrid item xs={12} style={{ marginTop: '3px' }}>
                <SubscribeButton
                  startIcon={<CartItemIcon />}
                  variant="outlined"
                  disableElevation
                  counter={counter}
                  className={`mpFe-uat-new-order-subscription-${index}`}
                  data-testid="items-number"
                >
                  <AddCartTypo type="notify">
                    {cartItemCount}
                  &nbsp;
                    { cartItemCount > 1 ? 'items' : 'item' }
                    &nbsp;
                    in cart
                  </AddCartTypo>
                </SubscribeButton>
              </AddCartGrid>
            )}
          </Grid>
        </AddCartListGrid>

      </ActionsContainer>
    </CardPaper>
  );

  if (view === 'grid') return renderGridView();
  return renderListView();
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  cart: PropTypes.bool,
  similarAction: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  refetchCart: PropTypes.func,
  view: PropTypes.string
};

ProductCard.defaultProps = {
  product: {},
  cart: false,
  refetchCart: () => {},
  view: 'grid'
};

export default ProductCard;
